import React, { useEffect, useState } from 'react';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { CONNECTION_SETTINGS } from 'constants/connectionSettings';
import { getCurrencyList } from '_metronic/_helpers/constant/optionData';
import { DatePickerDropdown } from './DatePickerDropdown';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { UIDates, theme } from '_metronic/_helpers';
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';
import { useQuery } from 'app/hooks';
import clsx from 'clsx';



export function DashboardFilter({ queryParams, setSearchQuery, setFilterQuery, connectionEntities }) {

  const query = useQuery();
  const locale = useLang();

  const [filterParamsBase, setFilterParamsBase] = useState({
    date_from: UIDates.dayCalculation(-30),
    date_to: UIDates.getCurrentDate(),
    marketplace: null,
    currency: "EUR"
  });


  useEffect(() => {

    const date_from = query.get('filter.date_from') ?? UIDates.dayCalculation(-30);
    const date_to = query.get('filter.date_to') ?? UIDates.getCurrentDate();
    const marketplace = query.get('filter.marketplace')
    const currency = query.get('filter.currency') ?? "EUR"

    setFilterParamsBase(prev => ({
      ...prev,
      date_from,
      date_to,
      marketplace: filteredConnections.map(i => i.name).includes(marketplace) ? marketplace : null,
      currency
    }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query])


  const onDeleteFilterHandler = (key) => {
    setFilterParamsBase(prev => ({
      ...prev,
      [key]: false,
    }));
    setFilterQuery('DELETE_PARAMS', key);
  };

  const onFilterHandler = (type, value) => {
    setFilterQuery(type, value)
    setFilterParamsBase(prev => ({
      ...prev,
      marketplace: value
    }))
  }

  const sortedTokens = CONNECTION_SETTINGS
    .filter(token => token.active && !(token.type === "shipper"))
    .sort((a, b) => {
      const typeOrder = { marketplace: 0, shop: 1, shipper: 2 };
      const typeComparison = typeOrder[a.type] - typeOrder[b.type];
      if (typeComparison === 0) {
        return a.name.localeCompare(b.name);
      }
      return typeComparison;
    });

  const connections = connectionEntities.filter(connection => connection.token_type !== 'shipper')
  const filteredConnections = sortedTokens.filter(obj => connections.some(connection => connection.name === obj.name));

  // Marketplace none filter
  filteredConnections.push({
    name: 'none',
    label: <FormattedMessage id="GENERAL.FILTER.NONE_MARKET" />,
    icon: <Icon.ShopWindow />
  })

  const selectMarketplace = filteredConnections.find(i => i.name === filterParamsBase.marketplace);

  const selectCurrency = getCurrencyList(locale).find(i => i.value === filterParamsBase.currency);


  return (
    <Row>
      <Col className='d-flex align-items-start justify-content-start px-md-0'>

        {/* ----------------------Currency filter started--------------------------------------- */}
        <Dropdown drop="down" className="dropdown-inline symbol">

          <Dropdown.Toggle variant="outline-secondary" className='d-flex justify-content-between align-items-center font-weight-bold' style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic-currency">
            <span className="icon-md">
              {selectCurrency?.symbol}
            </span>
            <span className="d-none d-sm-inline ml-3">
              {selectCurrency?.name ?? <FormattedMessage id="DOCUMENT.FILTER.MARKET" />}
            </span>
          </Dropdown.Toggle>

          {selectCurrency && <i className="symbol-badge bg-danger"></i>}
          <Dropdown.Menu className="dropdown-menu-md dropdown-menu-anim-up" id="dropdown_currency_options">
            <ul className="navi navi-hover">
              {getCurrencyList(locale)?.map((opt, index) => (
                <li className="navi-item" key={index}>
                  <Dropdown.Item className={clsx('navi-link font-weight-bold', { active: opt.value === selectCurrency?.value })} onClick={() => onFilterHandler('currency', opt.value)} id={opt.value}>
                    <span className="icon-md mr-2">
                      {opt.symbol}
                    </span>
                    <span className="navi-text text-nowrap">
                      {opt.name}
                    </span>
                  </Dropdown.Item>
                </li>
              ))}

              {/*  default currency eklenince açılacak */}
              {/*<Dropdown.Divider />
              <li className="navi-item">
                <Dropdown.Item
                  className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1'
                  onClick={() => onFilterHandler('currency', 'EUR')}
                  id='clear-currency'
                >
                  <FormattedMessage id='GENERAL.CLEAR' />
                </Dropdown.Item>
              </li> */}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
        {/* ----------------------Currency filter ended--------------------------------------- */}


        {/* ----------------------Market filter started--------------------------------------- */}
        <Dropdown drop="down" className="dropdown-inline symbol ml-2">

          <Dropdown.Toggle variant="outline-secondary" className='d-flex justify-content-between align-items-center font-weight-bold' style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <span className="svg-icon svg-icon-sm">
              {selectMarketplace?.icon ?? <Icon.Shop />}
            </span>
            <span className="d-none d-sm-inline ml-2">
              {selectMarketplace?.label ?? <FormattedMessage id="DOCUMENT.FILTER.MARKET" />}
            </span>
          </Dropdown.Toggle>

          {selectMarketplace && <i className="symbol-badge bg-danger"></i>}
          <Dropdown.Menu className="dropdown-menu-md dropdown-menu-anim-up" id="dropdown_market_options">
            <ul className="navi navi-hover">
              {filteredConnections.length
                ? <>
                  {filteredConnections?.map((opt, index) => (
                    <li className="navi-item" key={index}>
                      <Dropdown.Item className={clsx('navi-link font-weight-bold', { active: opt.name === selectMarketplace?.name })} onClick={() => onFilterHandler('marketplace', opt.name)} id={`dropdown_item_${opt.name}`}>
                        <span className="svg-icon svg-icon-md mr-2">
                          {opt.icon}
                        </span>
                        <span className="navi-text text-nowrap font-weight-bold">
                          {opt.label}
                        </span>
                      </Dropdown.Item>
                    </li>
                  ))}

                  <Dropdown.Divider />

                  <li className="navi-item">
                    <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' onClick={() => onDeleteFilterHandler('marketplace')} id='clear-marketplace'>
                      <FormattedMessage id='GENERAL.CLEAR' />
                    </Dropdown.Item>
                  </li>
                </>
                : <li className="navi-item px-3">
                  <span className="svg-icon svg-icon-sm text-info text-hover-primary mr-2">
                    <Icon.InfoCircle />
                  </span>
                  <span className="navi-text">
                    <FormattedHTMLMessage id="DOCUMENT.SHIPPING.NO_MARKETPLACE_FILTER" values={{ url: ModuleRoutes.CONNECTIONS, a: chunks => <a href='/' id="btn_add_marketplace">{chunks}</a> }} />
                  </span>
                </li>
              }
            </ul>
          </Dropdown.Menu>
        </Dropdown>

        {/* ----------------------Market filter ended--------------------------------------- */}

        <DatePickerDropdown setFilterQuery={setFilterQuery} />

      </Col>
    </Row>
  );
}
