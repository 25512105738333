import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls'
import { CreationAndUpdateInfo, BackButton } from '_metronic/_partials'
import SupportPreviewCard from './SupportPreviewCard';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { SupportActions } from '../_redux/actions';
import { getLastSearch } from '_metronic/_helpers';
import { FormattedMessage, useIntl  } from 'react-intl';
import { Button } from 'react-bootstrap';
import { Icon } from '_metronic/_icons'



export default function SupportEdit({ id }) {

  const history = useHistory();
  const dispatch = useDispatch();
  const lastSearch = getLastSearch()

  useEffect(() => {
    dispatch(SupportActions.getTicketById(id));
  }, [dispatch, id]);

  const { ticket, loading } = useSelector((state) => ({
    ticket: state.supports.ticket,
    loading: state.supports.loading,
  }))

  useEffect(() => {
    return () => {
      dispatch(SupportActions.cleanUpReducer());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { formatMessage: intl } = useIntl();

  return (
    <Card className="card-box">

      <CardHeader
        back={<BackButton link={ModuleRoutes.SUPPORT + lastSearch} disabled={loading} />}
        title={`${ticket?.number ?? "View"} ${intl({ id: "SUPPORT.TICKET" })}` }
        id={'title_supplier_edit'}
        sticky
      >
        <CardHeaderToolbar>

          {!['cancelled', 'completed', 'archived'].includes(ticket?.status) &&
            <Button variant='primary' className='d-flex font-weight-bold svg-icon svg-icon-sm ml-2' onClick={() => history.push(ModuleRoutes.EDIT_TICKET_FN(id))} id='btn_reply_ticket' disabled={loading}>
              <Icon.Reply />
              <span className="d-none d-sm-inline ml-2">
                <FormattedMessage id='SUPPORT.REPLY' />
              </span>
            </Button>}

        </CardHeaderToolbar>

      </CardHeader>

      <CardBody>

        <SupportPreviewCard ticket={ticket} />

      </CardBody>

      <CreationAndUpdateInfo data={ticket} />

    </Card>
  )
}
