
import React, { useState, useEffect } from 'react';
import { SearchControl } from '_metronic/_partials/controls';
import { LICENSE_STATUS, ACCOUNT_STATUS_FILTER } from '../../_context/AdminUIHelper';
import { Col, Dropdown, Row } from 'react-bootstrap';
import { theme } from '_metronic/_helpers';
import { useQuery } from 'app/hooks';
import clsx from 'clsx';



export function UserFilter({ UIProps }) {

  const query = useQuery();
  const [search, setSearch] = useState(query.get('search') ?? '');
  const [filterParamsBase, setFilterParamsBase] = useState({
    status: "active",
    license: "all_licenses"
  });

  useEffect(() => {
    const status = query.get('filter.status');
    const license = query.get('filter.license');

    setFilterParamsBase(prev => ({
      ...prev,
      status: ACCOUNT_STATUS_FILTER.map(item => item.value).includes(status) ? status : "all_statuses",
      license: LICENSE_STATUS.map(item => item.value).includes(license) ? license : "all_licenses"
    }));

  }, [query])

  useEffect(() => {
    setSearch(query.get('search') ?? '');
  }, [query]);

  const handleSearch = (values) => {
    setSearch(values);
    UIProps.setSearchQuery(values);
  }

  const onFilterLicenseHandler = (value) => {
    UIProps.setFilterQuery('license', value);
    setFilterParamsBase(prev => ({
      ...prev,
      license: value,
    }));
  };

  const onFilterAccountStatusHandler = (value) => {
    UIProps.setFilterQuery('status', value);
    setFilterParamsBase(prev => ({
      ...prev,
      status: value,
    }));

  };

  return (
    <Row className="mb-3">

      <Col sm="12" md="6" lg="4" className="mb-lg-0 mb-2">
        <SearchControl search={search} handleSearch={handleSearch} />
      </Col>

      <Col sm="12" md="6" lg="8" className='d-flex align-items-start justify-content-start px-md-0'>

        <Dropdown className="dropdown-inline" drop="down">
          <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <span className={clsx("label label-xl label-dot mr-3", `label-${LICENSE_STATUS.find(item => item.value === filterParamsBase.license)?.className}`)}></span>
            {LICENSE_STATUS.find(i => i.value === filterParamsBase?.license)?.label}
          </Dropdown.Toggle>

          <Dropdown.Menu className='dropdown-menu-right dropdown-menu-anim-down' id='dropdown_document_grouping'>
            <ul className="navi navi-hover">
              {LICENSE_STATUS.map((filter) => (
                <div key={filter.value}>
                  <li className="navi-item">
                    {filter.value === 'all_licenses' && <Dropdown.Divider />}
                    <Dropdown.Item className='navi-link font-weight-bold' id={`dropdown_item_${filter.value}`}
                      onClick={() => onFilterLicenseHandler(filter.value)}>
                      <span className={clsx("label label-xl label-dot mr-1", `label-${filter.className}`)}></span>
                      <span className="navi-text">
                        {filter.label}
                      </span>
                    </Dropdown.Item>
                  </li>
                </div>
              ))}
            </ul>
          </Dropdown.Menu>
        </Dropdown>


        <Dropdown className="dropdown-inline ml-3" drop="down">
          <Dropdown.Toggle variant="outline-secondary" className="d-flex align-items-center svg-icon svg-icon-sm font-weight-bold" style={{ height: theme.units.input.height['sm'] }} id="dropdown-basic">
            <span className={clsx("label label-xl label-dot mr-3", `label-${ACCOUNT_STATUS_FILTER.find(item => item.value === UIProps.queryParams.filter?.status)?.className}`)}></span>
            {ACCOUNT_STATUS_FILTER.find(i => i.value === UIProps.queryParams.filter?.status)?.label}
          </Dropdown.Toggle>

          <Dropdown.Menu className='dropdown-menu-right dropdown-menu-anim-down' id='dropdown_document_grouping'>
            <ul className="navi navi-hover">
              {ACCOUNT_STATUS_FILTER.map((filter) => (
                <li className="navi-item" key={filter.value}>
                  {filter.value === 'all_statuses' && <Dropdown.Divider />}
                  <Dropdown.Item className='navi-link font-weight-bold' id={`dropdown_item_${filter.value}`}
                    onClick={() => onFilterAccountStatusHandler(filter.value)}>
                    <span className={clsx("label label-xl label-dot mr-1", `label-${filter.className}`)}></span>
                    <span className="navi-text">
                      {filter.label}
                    </span>
                  </Dropdown.Item>
                </li>
              ))}
            </ul>
          </Dropdown.Menu>
        </Dropdown>
      </Col>
    </Row>
  );
}
