import React, { useMemo } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useCustomersUIContext } from "../_context/CustomersUIContext";
import { ModuleRoutes } from "constants/moduleRoutes";
import { SelectCounter } from "_metronic/_partials";
import { Icon } from '_metronic/_icons';



export function CustomersGrouping() {

  const history = useHistory();

  const UIContext = useCustomersUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
  }), [UIContext]);

  const deleteAllSelectedCustomer = () => {
    const ids = UIProps.ids.join("&");
    history.push(ModuleRoutes.DELETE_CUSTOMER_LISTS_FN(ids))
  };


  return (
    <div className="d-flex justify-content-between">

      <SelectCounter count={UIProps.ids.length} />

      <Button variant="light-primary" type="button" className="svg-icon svg-icon-sm font-weight-bold ml-auto" onClick={deleteAllSelectedCustomer} disabled={UIProps.ids.length <= 0} id="btn_multiple_delete">
        <Icon.Trash />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="GENERAL.DELETE" />
        </span>
      </Button>

    </div>
  );
}
