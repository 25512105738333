/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Icon } from '_metronic/_icons';



export const ActionsColumnFormatter = (cellContent, row, rowIndex, { openArchiveRelocateDialog, openArchivePrintDialog }) => (
  <>
    <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip"><FormattedMessage id="ARCHIVE.UNARCHIVE_MESSAGE" /></Tooltip>}>
      <a className="btn btn-icon btn-hover-danger btn-sm" onClick={() => openArchiveRelocateDialog([row.id])} id={`btn_delete_${row.id}`}>
        <span className="svg-icon svg-icon-md">
          <Icon.Reply />
        </span>
      </a>
    </OverlayTrigger>

    <OverlayTrigger overlay={<Tooltip id="document-print-tooltip"><FormattedMessage id="GENERAL.PRINT" /></Tooltip>}>
      <a className="btn btn-icon btn-hover-primary btn-sm" onClick={() => openArchivePrintDialog(row.id)} id={`btn_print_${row.id}`}>
        <span className="svg-icon svg-icon-md">
          <Icon.Printer />
        </span>
      </a>
    </OverlayTrigger>


    {/*<OverlayTrigger overlay={<Tooltip id="products-delete-tooltip"><FormattedMessage id="PRODUCT.PRODUCT_DELETE" /></Tooltip>}>
      <a className="btn btn-icon btn-hover-danger btn-sm" onClick={() => openDeleteProductDialog(row.id)} id={`btn_delete_${row.id}`}>
        <span className="svg-icon svg-icon-md">
          <Icon.Trash/>
        </span>
      </a>
    </OverlayTrigger>*/}
  </>
);
