import React from 'react'
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls'
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { BlankMessage } from "_metronic/_partials/components";
import { IMPORT_TITLE } from '../_context/ImportExportUIHelper';
import { importExportActions } from "../_redux/actions";
import { FormattedMessage } from 'react-intl'
import { UIDates } from '_metronic/_helpers';
import { useDispatch } from "react-redux";
import { useLang } from '_metronic/i18n';
import { Icon } from '_metronic/_icons';



export default function ImportTable({ importData, loading }) {

  const dispatch = useDispatch();
  const locale = useLang();

  const selectModule = (item) => IMPORT_TITLE?.find(x => x.module === item)

  return (
    <Card className='gutter-b'>

      <CardSubHeader title={<FormattedMessage id='IMPORT_EXPORT.IMPORT_LIST' />} icon={<Icon.TextIndentLeft />} />

      <CardBody>
        {importData?.length > 0 ?
          <Table className="table-head-custom table-head-bg table-vertical-center" responsive>
            <thead>
              <tr className="text-left text-uppercase">
                <th className="pl-7">
                  <FormattedMessage id="GENERAL.NAME" />
                </th>
                <th className="text-center">
                  <FormattedMessage id="IMPORT_EXPORT.IMPORT.AT" />
                </th>
                <th className="text-right pr-7">
                  <FormattedMessage id="GENERAL.ACTIONS" />
                </th>
              </tr>
            </thead>
            <tbody>

              {importData?.map((item, index) => (
                <tr key={index}>
                  <td className="pl-0 pl-md-5 py-3">

                    <div className="d-flex align-items-center">

                      <div className="symbol symbol-40 symbol-light-primary mr-5">
                        <div className="symbol-label">
                          <span className="svg-icon svg-icon-lg">
                            {item?.module && (selectModule(item.module)?.icon ?? <Icon.Box />)}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-start flex-column font-weight-bolder">
                        <div className="text-dark-75 text-hover-primary font-size-lg"  type="button" onClick={() => dispatch(importExportActions.importExportDownload(item, locale))}>
                          {item?.module && <FormattedMessage id={selectModule(item.module)?.label ?? "IMPORT_EXPORT.IMPORT"} />}
                        </div>
                        <div className="font-weight-bold text-dark-25">
                          <FormattedMessage id='IMPORT_EXPORT.ACCOUNTING.IMPORT.DATA_LENGTH' values={{ data: item?.items }} />
                        </div>
                      </div>

                    </div>

                  </td>

                  <td className="text-center">
                    <div className="text-dark-50 font-weight-bold">
                    {UIDates.dateFormatter(item.created_at)}
                    </div>
                  </td>
                  <td>
                    <div className="d-flex justify-content-end pr-3">

                      <OverlayTrigger overlay={<Tooltip id="products-download-tooltip"><FormattedMessage id="IMPORT_EXPORT.EXPORT.DOWNLOAD" /></Tooltip>}>
                        <span className="btn btn-icon btn-hover-success btn-sm" onClick={() => dispatch(importExportActions.importExportDownload(item, locale))} id={`btn_download_${item?.id}`}>
                          <span className="svg-icon svg-icon-md">
                            <Icon.Download />
                          </span>
                        </span>
                      </OverlayTrigger>

                      <OverlayTrigger overlay={<Tooltip id="products-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
                        <span className="btn btn-icon btn-hover-danger btn-sm" onClick={() => dispatch(importExportActions.importExportDelete(item.id))} id={`btn_delete_${item?.id}`}>
                          <span className="svg-icon svg-icon-md">
                            <Icon.Trash />
                          </span>
                        </span>
                      </OverlayTrigger>

                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          : <BlankMessage
            icon={<Icon.TextIndentLeft />}
            title={<FormattedMessage id='IMPORT_EXPORT.IMPORT.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='IMPORT_EXPORT.IMPORT.BLANK_MESSAGE' />}
            loading={loading}
          />
        }
      </CardBody>

    </Card>
  )
}
