import React, { useEffect } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { Card, CardHeader } from "_metronic/_partials/controls";
import { UserGuideHelper } from "../../../user-guides/UserGuideHelper";
import { LoadingDialog } from "_metronic/_partials/loading";
import { CardBody } from "_metronic/_partials/controls";
import { importExportActions } from "../_redux/actions";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-bootstrap";
import ExportTable from "./ExportTable";
import ExportSelect from "./ExportSelect";
import { Icon } from '_metronic/_icons';



export default function ExportPage() {

  const dispatch = useDispatch();

  const { loading, transportData } = useSelector(state => ({
    loading: state.importExport.loading,
    transportData: state.importExport.transportData
  }), shallowEqual);


  useEffect(() => {
    dispatch(importExportActions.getImportExports("export"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Card className="card-box">

      <CardHeader
        icon={<Icon.CloudDownload />}
        title={<FormattedMessage id="IMPORT_EXPORT.EXPORT" />}
        info={<UserGuideHelper />}
        className="display-5"
        id='title_accounting_export'
        sticky
      />

      <CardBody className="d-flex flex-column">

        <Row>
          <Col md="9">
            <ExportTable exportData={transportData} loading={loading} />
          </Col>

          <Col md="3">
            <ExportSelect loading={loading} />
          </Col>

        </Row>

      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  )
}
