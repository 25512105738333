import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import * as columnFormatters from './column-formatters/ColumnFormatters';
import { BlankMessage } from '_metronic/_partials/components';
import * as uiHelpers from '../_context/DocumentsUIHelpers';
import { convertPriceCurrency } from '_metronic/_helpers';
import { DocumentActions } from '../_redux/actions';
import { Icon } from '_metronic/_icons';
import { Portal } from 'react-portal';



export function PaymentWarningsTable() {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    show: UIContext.showPaymentWarnings,
    onHide: UIContext.closePaymentWarningsDialog,
    documentId: UIContext.documentId,
    openPaymentWarningsDialog: UIContext.openPaymentWarningsDialog,
    queryParams: UIContext.queryParams,
    openPrintDocumentDialog: UIContext.openPrintDocumentDialog,
    requestedDocument: UIContext.requestedDocument,
    deletePaymentWarningsDialog: UIContext.deletePaymentWarningsDialog,
    openPaymentReminderDialog: UIContext.openPaymentReminderDialog,
    handleOpenSendEmailsDialog: UIContext.handleOpenSendEmailsDialog,
    setIds: UIContext.setIds,
    setReminder: UIContext.setReminder,
  }), [UIContext]);


  const documents = useSelector(state => state.documents.documents);
  const findDocument = documents?.find(doc => doc.id === UIProps.documentId)


  const openPaymentWarningsDialog = (row) => {
    const data = [{
      id: UIProps.documentId,
      type: UIProps.requestedDocument.type,
      ...row,
    }]
    dispatch(DocumentActions.printDocument(data, UIProps.queryParams));
    UIProps.openPrintDocumentDialog();
  }

  const paymentWarningsHandler = (row) => {
    UIProps.openPaymentReminderDialog(UIContext.documentId, row)
  }

  const openSendEmailsDialog = (row) => {

    UIProps.setIds([{ [UIProps.requestedDocument.type]: UIProps.documentId }])
    UIProps.setReminder(row.reminder)
    UIProps.handleOpenSendEmailsDialog()

  };

  const COLUMNS = [
    {
      dataField: '#',
      text: intl({ id: 'GENERAL.NO' }),
      isDummyField: true,
      headerStyle: { minWidth: '40px', width: "10%" },
      headerClasses: 'text-center',
      classes: 'text-center font-weight-bold text-dark-50',
      editable: false,
      formatter: (cell, row, rowIndex) => (rowIndex + 1),
    },
    {
      dataField: 'created_at',
      text: intl({ id: 'GENERAL.DATE' }),
      headerStyle: { minWidth: '60px', width: "20%" },
      headerClasses: 'text-left',
      classes: 'text-left font-weight-bold text-dark-75',
      editable: false,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "left" }),
      formatter: columnFormatters.DateFormatter,
    },
    {
      dataField: 'type',
      text: "Type",
      headerStyle: { minWidth: '120px', width: "30%" },
      headerClasses: 'text-left',
      classes: 'text-left font-weight-bold text-dark-75',
      editable: false,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "left" }),
      formatter: (cell, row, rowIndex) => (uiHelpers.paymentWarnings?.find(item => item.value === cell)?.label),
    },
    {
      dataField: 'amount',
      text: intl({ id: 'GENERAL.AMOUNT' }),
      headerStyle: { minWidth: '60px', width: "20%" },
      headerClasses: 'text-right',
      classes: 'text-right font-weight-bold text-dark-75',
      editable: false,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "right" }),
      formatter: (cell, row, rowIndex) => (convertPriceCurrency(cell, findDocument?.pricing_summery?.currency))
    },
    {
      dataField: 'total_amount',
      text: intl({ id: 'DOCUMENT.POSITION_TOTAL_AMOUNT' }),
      headerStyle: { minWidth: '120px', width: "30%" },
      headerClasses: 'text-right',
      classes: 'text-right font-weight-bold text-dark-75',
      editable: false,
      editorStyle: () => ({ height: "32px", padding: "0.5rem 0.75rem", textAlign: "right" }),
      formatter: (cell, row) => columnFormatters.TotalAmountFormatter(cell, row, findDocument?.payment_warnings, findDocument?.pricing_summary),
    },
    {
      dataField: 'action',
      text: "Actions",
      headerStyle: { minWidth: '150px', width: "10%" },
      headerClasses: 'text-center',
      classes: 'text-center',
      editable: false,
      formatter: columnFormatters.ActionFormatter,
      formatExtraData: {
        openPaymentWarningsDialog,
        openSendEmailsDialog,
        paymentWarnings: findDocument?.payment_warnings,
        openPaymentWarningsDelete: UIProps.deletePaymentWarningsDialog
      },
    },
  ];


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.show} onHide={UIProps.onHide} size="lg" backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-v-not-centered" centered>

        <Modal.Header>

          <Modal.Title>
            <Icon.Bell className="mr-3" />
            <FormattedMessage id='DOCUMENT.PAYMENT.WARNINGS' />
          </Modal.Title>

          {<Dropdown className="dropdown-inline ml-3" drop="down" alignRight>
            <Dropdown.Toggle variant="primary" className="svg-icon svg-icon-sm font-weight-bold" id="dropdown-basic" disabled={findDocument?.payment_status === "paid"}>
              <Icon.Plus className="mr-3" />
              <FormattedMessage id='GENERAL.ADD' />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-left global-dropdowc-cls" id='dropdown_document_formatter'>
              <ul className="navi navi-hover">
                {uiHelpers.paymentWarnings.map((filter, index) => (
                  (filter.value === "reminder" || !findDocument?.payment_warnings?.find(i => i.type === filter.value)) &&
                  <li className="navi-item" key={index}>
                    {/* eslint-disable jsx-a11y/anchor-is-valid */}
                    <Dropdown.Item className="navi-link icon-xl py-2" onClick={() => paymentWarningsHandler(filter.value)} id={`dropdown_item_${filter.value}`}>
                      {filter.value === "reminder" ? <Icon.Bell className="icon-md" /> : <Icon.AppIndicator className="icon-md" />}
                      <span className="navi-text text-nowrap">{filter.label}</span>
                    </Dropdown.Item>
                  </li>
                ))}
              </ul>
            </Dropdown.Menu>
          </Dropdown>}

        </Modal.Header>

        <Modal.Body className='p-0'>

          {findDocument?.payment_warnings?.length === 0
            ? <BlankMessage
              icon={<Icon.Layers />}
              title={<FormattedMessage id='DOCUMENT.PAYMENT.WARNINGS.BLANK_MESSAGE_TITLE' />}
              message={<FormattedMessage id='DOCUMENT.PAYMENT.WARNINGS.BLANK_MESSAGE' />}
              arrow
            />
            :
            <BootstrapTable
              keyField="id"
              data={findDocument?.payment_warnings || []}
              columns={COLUMNS}
              wrapperClasses="table-responsive"
              headerClasses="bg-info-o-10"
              classes="table table-head-custom table-vertical-center"
              bootstrap4
              bordered={false}
              deleteRow={true}
              condensed
            />}

        </Modal.Body>

        <Modal.Footer>

          <Button variant='light' onClick={UIProps.onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
