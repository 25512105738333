import { useEffect } from 'react';
import { useDispatch } from 'react-redux';



export const useGetProductsEffect = (queryParams, dispatcher, ...deps) => {
  const dispatch = useDispatch();
  const { search, pagination, order } = queryParams;
  
  useEffect(() => {
    let delayDebounceFn;
    if (search) delayDebounceFn = setTimeout(() => dispatch(dispatcher(queryParams)), 350);
    else dispatch(dispatcher(queryParams));

    return () => {
      clearTimeout(delayDebounceFn);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, pagination.page, pagination.limit, order, dispatch, ...deps]);
};
