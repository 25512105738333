/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { getConnection } from 'constants/connectionSettings';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function OrderIdFormatter(cell, row, rowIndex) {

  const order = <div className='d-flex align-items-center'>
    <span className='svg-icon svg-icon-md'>
      {getConnection(row?.marketplace)?.icon ?? <Icon.ShopWindow className="text-dark-25" />}
    </span>
    <span className='font-weight-bold ml-3'>
      {cell ? cell : row.id}
    </span>
  </div>

  return (
    row?.marketplace_url
      ? <OverlayTrigger overlay={<Tooltip id="market-tooltip">{row?.marketplace_url}</Tooltip>}>
        {order}
      </OverlayTrigger>
      : order
  )
}


export function ShippingFormatter(cell, row, rowIndex) {
  return (
    <OverlayTrigger overlay={<Tooltip id="market-tooltip">{cell[0]?.service}</Tooltip>}>
      <span className='svg-icon svg-icon-xl'>
        {getConnection(cell[0]?.service)?.icon ?? null}
      </span>
    </OverlayTrigger>
  )
}


export function PaymentStatusFormatter(cell, row, rowIndex) {
  return (
    <span className={`label label-inline label-lg label-${cell === 'paid' ? 'outline-success' : 'danger'}`}>
      <FormattedMessage id={cell === 'paid' ? "GENERAL.PAID" : "GENERAL.OPEN"} />
    </span>
  )
}


export function InvoiceFormatter(cell, row, rowIndex) {
  return (
    <OverlayTrigger overlay={<Tooltip id="create_invoice">{row?.error?.invoice ?? <FormattedMessage id='DOCUMENT.FULFILLMENT.SUCCESS' />} </Tooltip>}>
      <span className="svg-icon svg-icon-sm">
        {cell !== undefined && (
          cell
            ? <Icon.CheckCircleFill className="text-success" />
            : <Icon.CheckCircleFill className="text-warning" />
        )}
      </span>
    </OverlayTrigger>
  )
}


export function EmailFormatter(cell, row, rowIndex) {
  return (
    <OverlayTrigger overlay={<Tooltip id="create_invoice">{row?.error?.email ?? <FormattedMessage id='DOCUMENT.FULFILLMENT.SUCCESS' />}</Tooltip>}>
      <span className="svg-icon svg-icon-sm">
        {cell !== undefined && (
          cell
            ? <Icon.CheckCircleFill className="text-success" />
            : <Icon.BanFill className="text-warning" />
        )}
      </span>
    </OverlayTrigger>
  )
}


export function FulfillmentFormatter(cell, row, rowIndex) {
  return (
    <OverlayTrigger overlay={<Tooltip id="create_invoice">{row?.error?.fulfillment ?? <FormattedMessage id='DOCUMENT.FULFILLMENT.SUCCESS' />} </Tooltip>}>
      <span className="svg-icon svg-icon-sm">
        {cell !== undefined && (
          cell
            ? <Icon.CheckCircleFill className="text-success" />
            : <Icon.BanFill className="text-danger" />
        )}
      </span>
    </OverlayTrigger>
  )
}


export function ActionFormatter(cellContent, row, rowIndex, { deleteCompleteList }) {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id={`fulfillment-delete-tooltip-${rowIndex}`}><FormattedMessage id="DOCUMENT.FULFILLMENT.DELETE" /></Tooltip>}>
      <span className="d-inline-block">
        <a className="btn btn-icon btn-sm" onClick={() => deleteCompleteList(row?.id)} id={`btn_delete_${row?.id}`}>
          <Icon.Close className="text-danger" />
        </a>
      </span>
    </OverlayTrigger>
  );
}
