import React from "react";
import { FormattedMessage } from "react-intl";
import { toast } from "react-toastify";
import ActionTypes from "./actionTypes";



export class importExportActions {


  // Import Example
  static importExample = (data) => {
    return {
      type: ActionTypes.IMPORT_EXAMPLE,
      payload: {
        data
      },
    };
  };

  static importExampleSuccess = () => {
    return {
      type: ActionTypes.IMPORT_EXAMPLE_SUCCESS,
    };
  };

  static importExampleFail = () => {
    return {
      type: ActionTypes.IMPORT_EXAMPLE_FAIL,
    };
  };



  // All import-export data
  static getImportExports = (type) => {
    return {
      type: ActionTypes.IMPORT_EXPORTS_GET,
      payload: {
        type
      }
    };
  };

  static getImportExportsSuccess = (data) => {
    return {
      type: ActionTypes.IMPORT_EXPORTS_GET_SUCCESS,
      payload: {
        data
      }
    };
  };

  static getImportExportsFail = () => {
    return {
      type: ActionTypes.IMPORT_EXPORTS_GET_FAIL,
    };
  };



  // Get Export
  static getExport = ({ export_select, date_range, language, include_archived }) => {
    return {
      type: ActionTypes.EXPORT_GET,
      payload: {
        export_select,
        date_range,
        language,
        include_archived
      }
    };
  };

  static getExportSuccess = (data) => {
    return {
      type: ActionTypes.EXPORT_GET_SUCCESS,
      payload: {
        data
      }
    };
  };

  static getExportFail = (err) => {
    toast.warning(<FormattedMessage id={err.message} />);
    return {
      type: ActionTypes.EXPORT_GET_FAIL,
      payload: {
        err
      }
    };
  };


  // Delete Export
  static importExportDelete = (id) => {
    return {
      type: ActionTypes.IMPORT_EXPORT_DELETE,
      payload: {
        id
      }
    };
  };

  static importExportDeleteSuccess = (id) => {
    return {
      type: ActionTypes.IMPORT_EXPORT_DELETE_SUCCESS,
      payload: {
        id
      }
    };
  };

  static importExportDeleteFail = () => {
    return {
      type: ActionTypes.IMPORT_EXPORT_DELETE_FAIL,
    };
  };


  // Export Download
  static importExportDownload = (data, locale) => {
    return {
      type: ActionTypes.IMPORT_EXPORT_DOWNLOAD,
      payload: {
        data,
        locale
      },
    };
  };

  static importExportDownloadSuccess = () => {
    return {
      type: ActionTypes.IMPORT_EXPORT_DOWNLOAD_SUCCESS,
    };
  };

  static importExportDownloadFail = () => {
    return {
      type: ActionTypes.IMPORT_EXPORT_DOWNLOAD_FAIL,
    };
  };


  // Import Product Add
  static importPreparation = (data, type) => {
    return {
      type: ActionTypes.IMPORT_PREPARATION,
      payload: {
        data,
        type
      },
    };
  };

  // Import Product Update
  static importPreparationUpdate = (data) => {
    return {
      type: ActionTypes.IMPORT_PREPARATION_UPDATE,
      payload: {
        data
      },
    };
  };

  // Import Product Delete
  static importPreparationDelete = (id) => {
    return {
      type: ActionTypes.IMPORT_PREPARATION_DELETE,
      payload: {
        id
      },
    };
  };

  // Import Product Save
  static importProductCreate = (data, language) => {
    return {
      type: ActionTypes.IMPORT_PRODUCT,
      payload: {
        data,
        language
      },
    };
  };

  static importProductCreateSuccess = (data) => {
    toast.success(<FormattedMessage id="IMPORT.REDUX.PRODUCT_IMPORT_SUCCESS" values={{ quantity: data }} />);
    return {
      type: ActionTypes.IMPORT_PRODUCT_SUCCESS,
    };
  };

  static importProductCreateFail = () => {
    return {
      type: ActionTypes.IMPORT_PRODUCT_FAIL,
    };
  };




  // Import Customer Save
  static importCustomerCreate = (data, language) => {
    return {
      type: ActionTypes.IMPORT_CUSTOMER,
      payload: {
        data,
        language
      },
    };
  };

  static importCustomerCreateSuccess = (data) => {
    toast.success(<FormattedMessage id="IMPORT.REDUX.CUSTOMER_IMPORT_SUCCESS" values={{ quantity: data }} />);
    return {
      type: ActionTypes.IMPORT_CUSTOMER_SUCCESS,
    };
  };

  static importCustomerCreateFail = () => {
    return {
      type: ActionTypes.IMPORT_CUSTOMER_FAIL,
    };
  };



  // Import Category Save
  static importCategoryCreate = (data, language) => {
    return {
      type: ActionTypes.IMPORT_CATEGORY,
      payload: {
        data,
        language
      },
    };
  };

  static importCategoryCreateSuccess = (data) => {
    toast.success(<FormattedMessage id="IMPORT.REDUX.CATEGORY_IMPORT_SUCCESS" values={{ quantity: data }} />);
    return {
      type: ActionTypes.IMPORT_CATEGORY_SUCCESS,
    };
  };

  static importCategoryCreateFail = () => {
    return {
      type: ActionTypes.IMPORT_CATEGORY_FAIL,
    };
  };


  // check data
  static checkData = (module) => {
    return {
      type: ActionTypes.CHECK_DATA,
      payload: {
        module
      },
    };
  };

  static checkDataSuccess = (data) => {
    return {
      type: ActionTypes.CHECK_DATA_SUCCESS,
      payload: {
        data
      },
    };
  };

  static checkDataFail = () => {
    return {
      type: ActionTypes.CHECK_DATA_FAIL,
    };
  };



  //Clean up the redux state
  static cleanUpReducer = () => {
    return {
      type: ActionTypes.CLEAN_UP_REDUX_STATE,
    };
  };

  // Reset Form Handler
  static resetFormHandler = () => {
    return {
      type: ActionTypes.RESET_FORM_HANDLER,
    };
  };


}