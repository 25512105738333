import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Field, Form, Formik } from 'formik';
import { Card, CardBody, CardSubHeader, SVFormControl } from '_metronic/_partials/controls';
import { Row, Col, Button, OverlayTrigger, Tooltip, ListGroup } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { SettingsActions } from '../_redux/actions';
import { DeleteConfirm } from '_metronic/_partials/dialogs';
import { BlankMessage } from '_metronic/_partials/components';
import { UserInfoDropdown } from '_metronic/_partials';
import PerfectScrollbar from "react-perfect-scrollbar";
import { maxValidation } from '_metronic/_helpers';
import { VariantAddForm } from './VariantAddForm';
import { Icon } from '_metronic/_icons';
import * as Yup from 'yup';

const initialState = {
  addVariant: false,
  delete: false,
  aspect: false
}


export default function VariantSettings() {

  const { formatMessage: intl } = useIntl();
  const dispatch = useDispatch();

  const [show, setShow] = useState(initialState);
  const [isVariants, setIsVariants] = useState(null)
  const [indexVariant, setIndexVariant] = useState();

  const { id, variant_options } = useSelector(state => state.settings.documentSettingsForEdit, shallowEqual);

  const variantOptions = variant_options?.find((el) => el.name === isVariants?.name)

  useEffect(() => {
    if (variant_options?.length > 0) {
      if (isVariants === null) {
        setIsVariants(variant_options[0]);
      }
    }
    setShow(show => ({ ...show, aspect: false }));
  }, [variant_options, isVariants]);


  // delete variant
  const deleteVariant = (existVariant) => {
    const filteredVariant = variant_options.filter((variant) => {
      return variant.name !== existVariant
    })
    if (id) {
      dispatch(SettingsActions.patchUserSettings(id, { variant_options: filteredVariant }))
    }
  };


  // add aspects
  const handleSubmitAspect = (values, { resetForm }) => {
    if (values?.aspect_name?.trim() && variantOptions) {
      const updatedVariant = {
        ...variantOptions,
        aspects: [...variantOptions.aspects, values.aspect_name?.trim()?.toLowerCase()]
      };
      const updatedVariants = variant_options?.map(variant =>
        variant.name === isVariants.name ? updatedVariant : variant
      );
      if (id) {
        dispatch(SettingsActions.patchUserSettings(id, { variant_options: updatedVariants }))
      }
      resetForm()
    }
  }

  //delete aspects
  const deleteAspect = (index) => {
    if (variantOptions) {
      const updatedVariant = {
        ...variantOptions,
        aspects: variantOptions.aspects.filter((item, i) => i !== index),
      };
      const updatedVariants = variant_options.map((variant) =>
        variant.name === isVariants.name ? updatedVariant : variant
      );
      if (id) {
        dispatch(SettingsActions.patchUserSettings(id, { variant_options: updatedVariants }))
      }
    }
  }

  const handleSubmitSaveVariant = (values, aspects) => {
    const variantOptions = [...variant_options, { name: values.variant_name?.trim()?.toLowerCase(), aspects }];
    if (id) {
      dispatch(SettingsActions.patchUserSettings(id, { variant_options: variantOptions }))
    } else {
      const data = {
        settings_type: "products",
        variant_options: variantOptions,
        default_product: {
          tax: "",
          category: "",
          unit: "",
          currency: ""
        },
        stock_control: {
          kaufland: false,
          amazon: false,
          ebay: false,
        }
      }
      dispatch(SettingsActions.createUserSettings(data))
    }
  };

  const aspectValidationSchema = Yup.object().shape({
    aspect_name: Yup.string()
      .max(50, maxValidation("50"))
      .test('aspect_name', intl({ id: 'SETTINGS.PRODUCT.ASPECT_NAME.EXISTS' }),
        value => !variantOptions?.aspects?.some((aspect) => aspect === value?.trim()?.toLowerCase()))
  });


  return (
    <Card className="gutter-b" style={{ height: "400px" }}>

      <CardSubHeader
        icon={<Icon.Diagram3 />}
        title={<FormattedMessage id='SETTINGS.PRODUCT.VARIANT_TITLE' />}
        info={<UserInfoDropdown
          title={<FormattedMessage id='SETTINGS.PRODUCT.VARIANT_TITLE' />}
          description={<FormattedMessage id='USER_GUIDE.SETTINGS.PRODUCT_VARIANT' />} />}
      >

        <Button variant='outline-primary' size='sm' type="button" className="svg-icon font-weight-bold svg-icon-sm" onClick={() => setShow({ ...show, addVariant: true })} id='btn_add_variant' >
          <Icon.Plus />
          <span className="d-none d-sm-inline ml-2">
            <FormattedMessage id="PRODUCT.ADD_VARIANT.ADD_VARIANT" />
          </span>
        </Button>

      </CardSubHeader>

      <CardBody>
        {variant_options?.length > 0 ?
          <Row>
            <Col sm="6" className='border-right pr-sm-10 mb-5'>

              <PerfectScrollbar className="scroll pr-5 mr-n5" options={{ wheelSpeed: 1, wheelPropagation: false }} style={{ maxHeight: '300px' }}>
                <ListGroup variant="flush" className="active">

                  {variant_options?.map((variant, index) =>
                    <ListGroup.Item role='button' className={`${isVariants?.name === variant.name && 'text-primary bg-light-primary rounded'} d-flex justify-content-between align-items-center text-hover-primary preview py-1 px-5`} onClick={() => setIsVariants(variant)} key={index}	>
                      <span className='font-weight-bold text-hover-primary text-capitalize svg-icon svg-icon-sm'>
                        <Icon.Tags className="mr-2" />
                        {variant.name}
                      </span>

                      {isVariants?.name === variant.name ?
                        <Icon.ChevronRight className="text-primary m-3" />
                        :
                        <OverlayTrigger overlay={<Tooltip id="customers-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
                          <span type="button" className="btn btn-icon btn-sm text-muted text-hover-danger invisible" onClick={() => { setShow({ ...show, delete: true }); setIndexVariant(variant.name); }} id="btn_delete">
                            <span className="svg-icon">
                              <Icon.Trash />
                            </span>
                          </span>
                        </OverlayTrigger>
                      }
                    </ListGroup.Item>
                  )}

                </ListGroup>
              </PerfectScrollbar>

            </Col>

            <Col sm="6" className='pl-sm-10'>

              <Formik enableReinitialize={true} initialValues={{ aspect_name: "" }} validationSchema={aspectValidationSchema} onSubmit={handleSubmitAspect}>
                <Form>

                  <PerfectScrollbar className="scroll pr-5 mr-n5" options={{ wheelSpeed: 1, wheelPropagation: false }} style={{ maxHeight: '221px' }}>
                    <ListGroup variant="flush" className="active">

                      {variantOptions?.aspects?.map((aspect, index) =>
                        <ListGroup.Item className="d-flex justify-content-between align-items-center text-hover-primary svg-icon svg-icon-sm preview py-1 px-5" key={index}>
                          <span className='font-weight-bold text-capitalize'>
                            <Icon.Tag className="mr-2" />
                            {aspect}
                          </span>

                          <OverlayTrigger overlay={<Tooltip id="customers-delete-tooltip"><FormattedMessage id="GENERAL.DELETE" /></Tooltip>}>
                            <span className="btn btn-icon btn-sm text-muted text-hover-danger invisible" onClick={() => deleteAspect(index)} id="btn_delete">
                              <span className="svg-icon">
                                <Icon.Trash />
                              </span>
                            </span>
                          </OverlayTrigger>

                        </ListGroup.Item>
                      )}

                    </ListGroup>
                  </PerfectScrollbar>

                  <ListGroup variant="flush" className="active mt-5">

                    {show.aspect ?
                      <ListGroup.Item className="d-flex justify-content-between align-items-center px-3">

                        <Field component={SVFormControl}
                          {...{
                            name: "aspect_name",
                            className: 'w-100',
                            feedbackLabel: true,
                            required: true,
                            onClickSymbol: <Button variant='secondary' type="submit" id='btn_add_variant'><Icon.Plus /></Button>
                          }}
                        />

                        <OverlayTrigger placement="top" overlay={<Tooltip id={`remove-tooltip`}><FormattedMessage id="GENERAL.CANCEL" /></Tooltip>}>
                          <Button variant='icon' className="ml-6 mb-5" onClick={() => setShow({ ...show, aspect: false })}>
                            <Icon.Close className="text-danger" />
                          </Button>
                        </OverlayTrigger>

                      </ListGroup.Item>
                      :
                      <Button variant='link' className="font-weight-bold text-left" onClick={() => setShow({ ...show, aspect: true })} id='btn_add_variant'>
                        <Icon.Plus className="mr-2" />
                        <FormattedMessage id="PRODUCT.ADD_VARIANT.ADD_ASPECT" />
                      </Button>
                    }
                  </ListGroup>

                </Form>
              </Formik>

            </Col>

          </Row>
          : <BlankMessage
            icon={<Icon.Diagram3 />}
            title={<FormattedMessage id="PRODUCT.ADD_VARIANT.BLANK_MESSAGE_TITLE" />}
            message={<FormattedMessage id="PRODUCT.ADD_VARIANT.BLANK_MESSAGE" />}
            className="mx-auto"
            arrow
          />
        }
      </CardBody>

      <VariantAddForm show={show.addVariant} onHide={() => setShow(initialState)} variantOptions={variant_options} handleSubmitSaveVariant={handleSubmitSaveVariant} />

      <DeleteConfirm show={show.delete} onHide={() => setShow(initialState)} toDelete={() => { deleteVariant(indexVariant); setShow(initialState); }} />

    </Card>
  )
}
