import { API } from 'constants/apiUrl';



export const reportMiddleware = {

  getOrderList: async function (accessToken, dateRange) {
    return await API.get(`/order-list`, {
      params: {
        dateRange,
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
};
