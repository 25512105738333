
import { supplier } from './supplierGuides'
import { connectionsAdd, connections } from './connectionGuides';
import { dashboard } from './dashboardGuides';
import { customer } from './customerGuides';
import { products } from './productsGuides';
import { documentsCredit, documentsInvoice, documentsOffer, documentsOrder, documentsWaybill, documentsOrderList } from './documentsGuides';
import { productListings } from './productListingsGuides';
import { company } from './companyGuides';
import { category } from './categoryGuides';
import { archive } from './archiveGuides';
import { userSettings } from './userSettingsGuides';
import { support } from './supportGuides';
import { importInfo, exportInfo } from './import-exportGuides';



export const userGuides = [
  supplier,
  connectionsAdd,
  connections,
  documentsOrder,
  documentsOffer,
  documentsInvoice,
  documentsCredit,
  documentsWaybill,
  documentsOrderList,
  dashboard,
  customer,
  products,
  productListings,
  company,
  category,
  archive,
  userSettings,
  support,
  importInfo,
  exportInfo
];
