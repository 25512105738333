/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import { DropdownTopbarItemToggler } from "_metronic/_partials/dropdowns";
import { AuthActions } from "app/pages/auth/_redux/actions";
import { setLanguage, useLang } from "../../../i18n";
import { FormattedMessage } from "react-intl";
import { flags } from "_metronic/_helpers";



export function LanguageSelectorDropdown() {

  const lang = useLang();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { licenseType } = useSelector(state => ({
    licenseType: state.auth.license.type,
  }));

  const currentLanguage = flags.find((x) => x.lang === lang) ?? flags[0];

  const handleSetLanguage = (lang) => {
    if (lang.language !== currentLanguage.lang) {
      pathname.includes('/auth')
        ? setLanguage(lang)
        : dispatch(AuthActions.updateUsers(lang))
    }
  }


  return (
    <Dropdown drop="down" alignRight>

      <Dropdown.Toggle as={DropdownTopbarItemToggler} id="dropdown-toggle-my-cart">
        <OverlayTrigger placement="auto" overlay={<Tooltip id="language-panel-tooltip"><FormattedMessage id='GENERAL.SELECT_LANG' /></Tooltip>}>
          <div className="btn btn-icon btn-clean btn-lg" id="btn_language">
            <span className="svg-icon" alt={currentLanguage.name}>
              {currentLanguage.flag}
            </span>
          </div>
        </OverlayTrigger>
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround p-0 m-0" id="dropdown_language">
        <ul className="navi navi-hover py-4">

          {flags.map((language) => ((language.hide && !["admin", "development", "tester"].includes(licenseType)) ? null :
            <li key={language.lang} className="navi-item">
              <Dropdown.Item className='navi-link font-weight-bold' onClick={() => handleSetLanguage({ language: language.lang })} id={`dropdown_item_${language.lang}`}>
                <span className="svg-icon svg-icon-lg mr-3" alt={language.name}>
                  {language.flag}
                </span>
                <span className="navi-text font-weight-bold">
                  {language.name}
                </span>
              </Dropdown.Item>
            </li>
          ))}

        </ul>
      </Dropdown.Menu>

    </Dropdown>
  );
}
