import React from "react";
import { Field } from "formik";
import { Card, CardBody, CardSubHeader, SVFormControl } from "_metronic/_partials/controls";
import { FormattedMessage, useIntl } from "react-intl";
import { Col, Row } from "react-bootstrap";
import { Icon } from "_metronic/_icons";



const INPUTS = [
  {
    name: "name",
    label: "GENERAL.NAME",
    required: true,
  },
  {
    name: "company",
    label: "GENERAL.COMPANY",
    size: '8',
    required: true,
  },
  {
    type: "email",
    name: "email",
    label: "GENERAL.EMAIL",
    required: true,
  },
  {
    type: "tel",
    name: "phone",
    label: "GENERAL.PHONE",
  },
  {
    type: "tel",
    name: "mobile",
    label: "GENERAL.MOBILE",
  },
  {
    type: "tel",
    name: "fax",
    label: "GENERAL.FAX",
  },
  {
    name: "tax_number",
    label: "GENERAL.TAX_NUMBER",
  },
  {
    name: "vat_id",
    label: "GENERAL.US_NUMBER",
  },
];


export function General() {

  const { formatMessage: intl } = useIntl();

  return (
    <Card className="gutter-b">

      <CardSubHeader icon={<Icon.Tag />} title={<FormattedMessage id="GENERAL.GENERAL" />} />

      <CardBody>
        <Row>
          {INPUTS.map((item, i) => (
            <Col key={i} lg={item.size ?? "4"}>
              <Field component={SVFormControl}
                {...{
                  name: item.name,
                  type: item.type,
                  label: intl({ id: item.label }),
                  withFeedbackLabel: true,
                  feedbackLabel: true,
                  required: item.required
                }}
              />
            </Col>
          ))}
        </Row>
      </CardBody>


    </Card>
  );
}
