/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import { ResponsiveActionDropdown } from './ResponsiveActionDropdown';
import { useDocumentLocalUIContext } from '../_context/DocumentLocalUIContext';
import { DHL_ACCOUNT_OPTIONS } from 'app/modules/connections/_context/SettingsUIHelpers';
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl';
import { CONNECTION_SETTINGS } from 'constants/connectionSettings';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { DocumentActions } from '../_redux/actions';
import { SelectCounter } from "_metronic/_partials";
import { Button, Dropdown } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import { useQuery } from 'app/hooks';



export function DocumentsGrouping() {

  const query = useQuery();
  const dispatch = useDispatch();

  const { licenseType, connections } = useSelector(state => ({
    licenseType: state.auth.license.type,
    connections: state.connections.entities
  }), shallowEqual);

  const myShipper = connections?.filter((item) => item?.token_type === 'shipper') || [];
  const isDevelopment = ["development", "tester", "test_user"].includes(licenseType);

  const dhlSubscriptions = myShipper?.find((item) => item?.name === 'dhl_new')?.dhl?.subscriptions || [];

  const dhlAccountOptions = dhlSubscriptions.map(item => {
    const baseOption = DHL_ACCOUNT_OPTIONS?.find((opt) => opt.accountNumber === item.accountNumber);
    return baseOption;
  })
  const connectionOptions = myShipper?.map((item) => {
    const baseOption = CONNECTION_SETTINGS?.find((token) => token.name === item.name && token.active && token.product(isDevelopment));
    if (item.name === 'dhl') {
      const baseOptionWarenpost = CONNECTION_SETTINGS?.find((token) => token.name === "dhl_warenpost" && token.active);
      return [baseOption, baseOptionWarenpost].filter(option => option); // Filtreleme yaparak boş olmayanları döndür
    }
    if (item.name === 'dhl_new') {
      const baseOptionWarenpost = CONNECTION_SETTINGS?.find((token) => token.name === "dhl_new_warenpost" && token.active && token.product(isDevelopment) && dhlAccountOptions.some(item => item?.type === 'dhl_new_warenpost'));
      return [baseOption, baseOptionWarenpost].filter(option => option); // Filtreleme yaparak boş olmayanları döndür
    }
    return baseOption;
  }).flat() || [];

  const UIContext = useDocumentsUIContext();
  const contextLocal = useDocumentLocalUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    openLabelGroupingDialog: UIContext.openLabelGroupingDialog,
    openConvertDocumentsDialog: UIContext.openConvertDocumentsDialog,
    requestedDocument: UIContext.requestedDocument,
    handleOpenSendEmailsDialog: UIContext.handleOpenSendEmailsDialog,
    handleOpenConvertSelectedOrdersToInvoiceDialog: UIContext.handleOpenConvertSelectedOrdersToInvoiceDialog,
    documentQueryParams: contextLocal.queryParams,
    openDeleteDocumentDialog: UIContext.openDeleteDocumentDialog,
    openPrintDocumentDialog: UIContext.openPrintDocumentDialog,
  }),
    [
      UIContext.handleOpenConvertSelectedOrdersToInvoiceDialog,
      UIContext.handleOpenSendEmailsDialog,
      UIContext.ids,
      UIContext.openConvertDocumentsDialog,
      UIContext.openLabelGroupingDialog,
      UIContext.requestedDocument,
      contextLocal.queryParams,
      UIContext.openDeleteDocumentDialog,
      UIContext.openPrintDocumentDialog
    ]);

  const orderStatusOpen = !['completed', 'cancelled'].includes(query.get('filter.status'));
  const convertSelectedDocuments = () => {
    // paginations da diğer sayfalardan seçilenlerin doc gelmesi için > getDocumentByIds
    if (UIProps.ids?.length) {
      const getIds = [];
      UIProps.ids.forEach(id => Object.values(id).forEach(i => getIds.push(i)));
      dispatch(DocumentActions.getDocumentByIds(getIds, null))
    };
    UIProps.openConvertDocumentsDialog()
  };
  const sendSelectedDocumentsViaEmail = () => UIProps.handleOpenSendEmailsDialog();

  const openPrintDocumentDialog = (item) => {
    const getIds = [];
    UIProps.ids.forEach(id => Object.values(id).forEach(i => getIds.push({
      id: i,
      type: UIProps.requestedDocument.type,
      ...(item && { proforma: item })
    })));
    if (getIds.length > 0) {
      dispatch(DocumentActions.printDocument(getIds));
      UIProps.openPrintDocumentDialog();
    }
  };

  const openLabelGroupingDialog = (service) => {
    const getIds = [];
    UIProps.ids.forEach(id => Object.values(id).forEach(i => getIds.push(i)));
    if (getIds.length > 0) {
      dispatch(DocumentActions.createAllDocuments(getIds, service, null))
      UIProps.openLabelGroupingDialog(service)
    }
  };



  return (
    <div className="d-flex flex-wrap justify-content-end mb-2">

      <SelectCounter count={UIProps.ids.length} />

      {['orders', 'offers'].includes(UIProps.requestedDocument.type) &&
        <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold ml-auto mb-2"
          onClick={() => UIProps.openDeleteDocumentDialog()}
          disabled={!UIProps.ids || UIProps.ids.length <= 0}
          style={{ cursor: !UIProps.ids || (UIProps.ids.length <= 0 && 'not-allowed') }}
          id='btn_archive'
        >

          <Icon.Archive />
          <span className="d-none d-sm-inline ml-2">
            <FormattedMessage id="DOCUMENT.COLUMN_FORMATTER.SEND_TO_ARCHIVE" />
          </span>

        </Button>}

      <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold ml-2 mb-2"
        onClick={sendSelectedDocumentsViaEmail}
        disabled={!UIProps.ids || UIProps.ids.length <= 0}
        style={{ cursor: !UIProps.ids || (UIProps.ids.length <= 0 && 'not-allowed') }}
        id='btn_email_send'
      >

        <Icon.EnvelopeAt />
        <span className="d-none d-sm-inline ml-2">
          <FormattedMessage id="GENERAL.SEND_EMAIL" />
        </span>

      </Button>

      {UIProps.requestedDocument.type !== 'orders'
        ? <Button variant="light-primary" className="svg-icon svg-icon-sm font-weight-bold ml-2 mb-2"
          onClick={() => openPrintDocumentDialog(false)}
          disabled={!UIProps.ids || UIProps.ids.length <= 0}
          style={{ cursor: !UIProps.ids || (UIProps.ids.length <= 0 && 'not-allowed') }}
          id='btn_print'
        >

          <Icon.Printer />
          <span className="d-none d-sm-inline ml-2">
            <FormattedMessage id="GENERAL.PRINT" />
          </span>

        </Button>
        : <Dropdown className="dropdown-inline" drop="down">
          <Dropdown.Toggle variant='light-primary' className="svg-icon svg-icon-sm font-weight-bold ml-2 mb-2"
            style={{ cursor: !UIProps.ids || (UIProps.ids.length <= 0 && 'not-allowed') }}
            disabled={!UIProps.ids || UIProps.ids.length <= 0}
            id="dropdown-shipping"
          >

            <Icon.Printer />
            <span className="d-none d-sm-inline ml-2">
              <FormattedMessage id="GENERAL.PRINT" />
            </span>

          </Dropdown.Toggle>
          <Dropdown.Menu id='dropdown_document_grouping'>
            <ul className="navi navi-hover">
              <li className="navi-item">
                <Dropdown.Item className="navi-link" onClick={() => openPrintDocumentDialog(false)} id="dropdown_item_close_print">
                  <span className="svg-icon svg-icon-md mr-2">
                    <Icon.Printer />
                  </span>
                  <span className="font-weight-bold navi-text">
                    <FormattedMessage id="DOCUMENT.ORDER_PLURAL" />
                  </span>
                </Dropdown.Item>
              </li>

              <li className="navi-item">
                <Dropdown.Item className="navi-link" onClick={() => openPrintDocumentDialog(true)} id="dropdown_item_open_print">
                  <span className="svg-icon svg-icon-md mr-2">
                    <Icon.Printer />
                  </span>
                  <span className="font-weight-bold navi-text">
                    {/* FIXME: i18n */}
                    Proforma
                  </span>
                </Dropdown.Item>
              </li>

            </ul>
          </Dropdown.Menu>
        </Dropdown>}

      {['orders', 'invoices'].includes(UIProps.requestedDocument.type) &&
        <Dropdown className="dropdown-inline" drop="down">
          <Dropdown.Toggle variant='light-primary' className="font-weight-bold svg-icon svg-icon-sm ml-2 mb-2"
            style={{ cursor: !UIProps.ids || ((UIProps.ids.length <= 0 || !orderStatusOpen) && 'not-allowed') }}
            disabled={!UIProps.ids || UIProps.ids.length <= 0 || !orderStatusOpen}
            id="dropdown-shipping"
          >

            <Icon.Truck />
            <span className="d-none d-sm-inline ml-2">
              <FormattedMessage id="GENERAL.SHIPPING" />
            </span>

          </Dropdown.Toggle>
          <Dropdown.Menu id='dropdown_document_grouping'>
            <ul className="navi navi-hover">
              {connectionOptions?.length === 0
                ? <li className="navi-item px-3">
                  <span className="svg-icon svg-icon-sm text-info text-hover-primary mr-2">
                    <Icon.InfoCircle />
                  </span>
                  <span className="font-weight-bold navi-text">
                    <FormattedHTMLMessage id="DOCUMENT.SHIPPING.NO_SHIPPER_FILTER" values={{ url: ModuleRoutes.CONNECTIONS, a: chunks => <a href='/'>{chunks}</a> }} />
                  </span>
                </li>
                : connectionOptions?.map((opt, index) => (
                  <li className="navi-item" key={index}>
                    <Dropdown.Item className="navi-link" onClick={() => openLabelGroupingDialog(opt.name)} id="dropdown_item_label">
                      <span className="svg-icon svg-icon-md mr-2">
                        {opt.icon}
                      </span>
                      <span className="font-weight-bold navi-text text-nowrap">
                        {opt.label}
                      </span>
                    </Dropdown.Item>
                  </li>
                ))}
            </ul>
          </Dropdown.Menu>
        </Dropdown>}

      <ResponsiveActionDropdown />

      {UIProps.requestedDocument.type === 'orders' && (
        <Button variant="primary" className="svg-icon svg-icon-sm font-weight-bold ml-2 mb-2"
          onClick={convertSelectedDocuments}
          disabled={!UIProps.ids || UIProps.ids.length <= 0 || !orderStatusOpen}
          style={{ cursor: !UIProps.ids || ((UIProps.ids.length <= 0 || !orderStatusOpen) && 'not-allowed') }}
          id='btn_convert'
        >
          <Icon.CheckSquare />
          <span className="d-none d-sm-inline ml-2">
            <FormattedMessage id="GENERAL.CONVERT" />
          </span>
        </Button>
      )}

    </div>
  );
}
