import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage, useIntl } from "react-intl";
import { SVFormControl, SVReactSelect } from "_metronic/_partials/controls";
import { getCountryList, maxValidation, plzRegExp, streetRegExp } from '_metronic/_helpers';
import { CompanyActions } from '../_redux/action';
import { useLoadingHandler } from 'app/hooks';
import { Field, Form, Formik } from "formik";
import { Col, Row } from 'react-bootstrap';
import { useLang } from '_metronic/i18n';
import * as Yup from "yup";



const companyInitialValues = {
  company_name: '',
  owner: '',
  address: {
    address_line1: "",
    post_code: "",
    city: "",
    country_code: "DE"
  },
};

export default function CompanyInfo({ btnRef, setCurrentStep, currentStep }) {

  const locale = useLang();
  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  const { companyBaseData, user, loading } = useSelector(state => ({
    companyBaseData: state.company.companyBaseData,
    loading: state.company.loading,
    user: state.auth.user
  }));
  const isLoading = useLoadingHandler(loading, CompanyActions.clearLoadingEffects());


  useEffect(() => {
    dispatch(CompanyActions.getCompanyBaseData());
  }, [dispatch]);


  const handleSubmit = (values) => {
    companyBaseData.id
      ? dispatch(CompanyActions.updateCompanyBaseData(values, ''))
      : dispatch(CompanyActions.createCompany({ ...values, email: user.email }));

    if (currentStep < 2) { setCurrentStep(currentStep + 1); }
  };


  const INPUTS = [
    {
      name: "company_name",
      label: 'GENERAL.COMPANY',
      required: true
    },
    {
      name: "owner",
      label: 'GENERAL.OWNER',
      required: true
    },
    {
      name: "address.address_line1",
      label: 'ADDRESS.STREET',
      required: true
    },
    {
      name: "address.post_code",
      label: 'ADDRESS.PLZ',
      required: true
    },
    {
      name: "address.city",
      label: 'ADDRESS.CITY',
      required: true
    },
    {
      name: "address.country_code",
      label: 'ADDRESS.COUNTRY',
      options: getCountryList(locale),
      component: SVReactSelect,
      required: true,
      menuPlacement: 'top',
    }
  ]

  const companySchema = Yup.object().shape({
    company_name: Yup.string()
      .required(intl({ id: "GENERAL.COMPANY_REQUIRED" })),
    owner: Yup.string()
      .required(intl({ id: "GENERAL.OWNER_REQUIRED" })),
    address: Yup.object().shape({
      address_line1: Yup.string()
        .max(150, maxValidation("150"))
        .required(intl({ id: "ADDRESS.STREET_REQUIRED" }))
        .when('country_code', {
          is: 'DE',  // Sadece country_code 'DE' olduğunda matches kontrolü yap
          then: schema => schema.matches(streetRegExp, intl({ id: "ADDRESS.STREET_MATCHES" })),
          otherwise: schema => schema,  // Diğer durumlarda matches kontrolü olmadan devam et
        }),
      post_code: Yup.string()
        .max(10, intl({ id: "ADDRESS.MAX_CHAR" }, { name: "10" }))
        .when('country_code', {
          is: 'DE', // only apply the matches validation when country_code is 'DE'
          then: Yup.string().matches(plzRegExp, intl({ id: "ADDRESS.PLZ_MATCHES" }))
        })
        .required(intl({ id: "ADDRESS.POSTCODE_REQUIRED" }))
        .typeError(intl({ id: "ADDRESS.PLZ_TYP" })),
      city: Yup.string()
        .max(50, intl({ id: "ADDRESS.MAX_CHAR" }, { name: "50" }))
        .required(intl({ id: "ADDRESS.LOCATION_REQUIRED" })),
      country_code: Yup.string()
        .required(intl({ id: "ADDRESS.COUNTRY_REQUIRED" })),
    }),
  });


  return (
    <>
      <div className="alert alert-custom alert-notice alert-light-info mb-6">
        <div className='font-weight-bold'>
          <FormattedMessage id="SETTINGS.FIRST_LOGIN.COMPANY.INFO" />
        </div>
      </div>

      <Formik enableReinitialize={true} initialValues={companyBaseData.id ? companyBaseData : companyInitialValues} validationSchema={companySchema} onSubmit={handleSubmit}>

        <Form>
          <Row>
            {INPUTS.map((item, i) => (
              <Col lg="6" key={i}>
                <Field component={item.component || SVFormControl}
                  {...{
                    name: item.name,
                    label: intl({ id: item.label }),
                    options: item.options,
                    withFeedbackLabel: true,
                    feedbackLabel: true,
                    required: item.required,
                    menuPlacement: item.menuPlacement,
                    maxMenuHeight: 190,
                    disabled: isLoading,
                  }}
                />
              </Col>
            ))}
          </Row>
          <button type="submit" className='d-none' ref={btnRef} onSubmit={handleSubmit} />
        </Form>
      </Formik>
    </>
  )
}
