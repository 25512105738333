import React, { useState, createContext, useContext } from 'react';



const ProductsUIContext = createContext();
export const ProductsUIConsumer = ProductsUIContext.Consumer;
export const useProductsUIContext = () => useContext(ProductsUIContext);


export function ProductsUIProvider({ children }) {

  const [ids, setIds] = useState([]);

  //* Product details dialog
  const [productId, setProductId] = useState()
  const [showProductDetailsDialog, setShowProductDetailsDialog] = useState(false);
  const openProductDetailsDialog = id => {
    setProductId(id);
    setShowProductDetailsDialog(true);
  };
  const closeProductDetailsDialog = () => {
    setProductId(undefined);
    setShowProductDetailsDialog(false);
  };

  const [nettoBruttoPrice, setNettoBruttoPrice] = useState('price_is_netto');

  const [showProductLoadingDialog, setShowProductLoadingDialog] = useState(false);

  const handleCloseProductLoadingDialog = () => setShowProductLoadingDialog(false);


  const value = {
    ids,
    setIds,
    showProductLoadingDialog,
    setShowProductLoadingDialog,
    handleCloseProductLoadingDialog,

    // openShopsDialog: ids => history.push(ModuleRoutes.UPDATE_SHOPS_QUANTITY_FN(ids)),
    nettoBruttoPrice,
    setNettoBruttoPrice,

    productId,
    showProductDetailsDialog,
    closeProductDetailsDialog,
    openProductDetailsDialog
  };

  return (
    <ProductsUIContext.Provider value={value}>
      {children}
    </ProductsUIContext.Provider>
  );
}
