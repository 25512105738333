import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Button, Col, ListGroup, Row } from 'react-bootstrap';
import { BlankMessage } from '_metronic/_partials/components';
import { SVFormControl } from '_metronic/_partials/controls';
import PerfectScrollbar from "react-perfect-scrollbar";
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { Field } from 'formik';



export const VariantGenerateForm = ({ aspectsSelectHandler, variantSelectHandler, selectedOption, selectedVariants }) => {

  const [showAspectName, setShowAspectName] = useState(false);

  const { variant_options } = useSelector(state => state.settings.documentSettingsForEdit, shallowEqual);

  const getOptionAspects = option => {
    const selectedOption = variant_options?.find(item => item.name === option);
    return selectedOption ? selectedOption.aspects : [];
  };

  const isSelected = (aspect, option, selectedVariants) => {
    const findOption = selectedVariants.find(item => item.name === option);
    return findOption ? findOption.values.includes(aspect) : false;
  };

  useEffect(() => {
    setShowAspectName(false);
  }, [selectedOption])


  return (
    variant_options?.length ?
      <Row>

        <Col md="6" className="border-right mb-5">

          <PerfectScrollbar className="scroll pr-5 mr-n5" options={{ wheelSpeed: 1, wheelPropagation: false }} style={{ maxHeight: '410px' }}>
            <ListGroup variant="flush" className="active">

              {variant_options?.map((variant, index) =>
                <ListGroup.Item role='button' className={`${selectedOption === variant.name && 'text-primary bg-light-primary'} d-flex justify-content-between align-items-center py-3 px-5 font-weight-bold text-capitalize rounded border-0`} onClick={() => variantSelectHandler(variant.name)} key={index}>
                  <span className='font-weight-bold text-hover-primary text-capitalize svg-icon svg-icon-sm'>
                    <Icon.Tags className="mr-2" />
                    {variant.name}
                  </span>
                  {selectedOption === variant.name && <Icon.ChevronRight />}
                </ListGroup.Item>
              )}

            </ListGroup>
          </PerfectScrollbar>

        </Col>

        <Col md="6">

          <PerfectScrollbar className="scroll pr-5 mr-n5" options={{ wheelSpeed: 1, wheelPropagation: false }} style={{ maxHeight: '300px' }}>

            <ListGroup variant="flush" className="active">
              <div className="checkbox-list">
                {getOptionAspects(selectedOption).map((aspect, index) => (
                  <label key={index} className="checkbox font-weight-bold text-capitalize text-hover-primary pl-3">
                    <input type="checkbox" name={aspect} value={aspect} checked={isSelected(aspect, selectedOption, selectedVariants)} onChange={aspectsSelectHandler} id={`checkbox_${index}`} /> <span />
                    {aspect}
                  </label>
                ))}
              </div>
            </ListGroup>

          </PerfectScrollbar>

          <ListGroup variant="flush" className="active mt-5">

            {showAspectName ?
              <ListGroup.Item className="px-3">

                <Field component={SVFormControl}
                  {...{
                    name: "aspect_name",
                    className: 'w-100',
                    feedbackLabel: true,
                    required: true,
                    onClickSymbol: <Button variant='secondary' type="submit" id='btn_adds_variant'><Icon.Plus /></Button>
                  }}
                />

              </ListGroup.Item>
              :
              <Button variant='link' className="font-weight-bold text-left" onClick={() => setShowAspectName(true)} id='btn_add_variant'>
                <Icon.Plus className="mr-2" />
                <FormattedMessage id="PRODUCT.ADD_VARIANT.ADD_ASPECT" />
              </Button>
            }

          </ListGroup>

        </Col>

      </Row>
      : <BlankMessage
        icon={<Icon.Diagram3 />}
        title={<FormattedMessage id="PRODUCT.ADD_VARIANT.BLANK_MESSAGE_TITLE" />}
        message={<FormattedMessage id="PRODUCT.ADD_VARIANT.BLANK_MESSAGE" />}
        className="mx-auto"
        arrow
      />
  );
};