import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Checkbox, DropDownControl, RadioControl, SVFormControl, SVReactSelect } from '_metronic/_partials/controls';
import { LABEL_OPTIONS, SHIPPER_TYPE_OPTIONS, getShipperOptions } from 'app/modules/documents/_context/DocumentsUIHelpers';
import { DHL_ACCOUNT_OPTIONS } from 'app/modules/connections/_context/SettingsUIHelpers';
import { FormattedHTMLMessage, useIntl } from 'react-intl';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { Field, useFormikContext } from 'formik';
import { Row, Col } from 'react-bootstrap';



export function ShippingSettingsForm({ toggleLabels, shippingId }) {

  const { formatMessage: intl } = useIntl();

  const { connections } = useSelector(state => ({
    connections: state.connections.entities,
  }), shallowEqual);


  //DEUTSCHE POST
  const deutschePost = connections?.find(item => item?.token_type === 'shipper' && item?.name === 'deutsche_post') || null;

  //DHL
  const myShipper = connections?.filter((item) => item?.token_type === 'shipper') || [];
  const dhlSubscriptions = connections.find((item) => item?.name === 'dhl_new')?.dhl?.subscriptions || [];
  const dhlAccountOptions = dhlSubscriptions.map(item => {
    const baseOption = DHL_ACCOUNT_OPTIONS?.find((opt) => opt.accountNumber === item.accountNumber);
    return baseOption;
  })
  const { values: { service, type, dp_product_id }, setFieldValue } = useFormikContext();

  const labeledShipperOptions = myShipper?.map((item) => {
    const baseOption = LABEL_OPTIONS?.find((opt) => opt.value === item.name);
    if (item.name === 'dhl') {
      const baseOptionWarenpost = LABEL_OPTIONS?.find((opt) => opt.value === "dhl_warenpost");
      return [baseOption, baseOptionWarenpost].filter(option => option); // Boş olmayanları filtrele
    }
    if (item.name === 'dhl_new') {
      const baseOptionWarenpost = LABEL_OPTIONS?.find((opt) => opt.value === "dhl_new_warenpost" && dhlAccountOptions.some(item => item?.type === 'dhl_new_warenpost'));
      return [baseOption, baseOptionWarenpost].filter(option => option); // Boş olmayanları filtrele
    }
    return baseOption;
  }).flat().filter(i => type === "return" ? i.return : i) || [];

  // const unlabeledShipperOptions = getShipperOptions().filter(item => !['dhl_new', 'dhl_new_warenpost'].includes(item.value));
  const currentService = (toggleLabels && toggleLabels ? labeledShipperOptions : getShipperOptions()).find(i => i.value === service);

  useEffect(() => {
    if (!shippingId) {
      if (service === 'dhl_warenpost' || service === 'dhl_new_warenpost') {
        setFieldValue('weight', 1)
      }
      if (service === 'dhl' || service === 'dhl_new') {
        setFieldValue('weight', 2)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service])

  useEffect(() => {
    if (toggleLabels && service === 'deutsche_post') {
      const findDeutschePost = deutschePost?.deutsche_post?.product_list.find(item => item.id === dp_product_id)
      //setFieldValue('price', (+findDeutschePost?.price / 100) || 0);
      setFieldValue('dp_product_name', findDeutschePost?.name || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dp_product_id])

  const newOptions = myShipper?.map((item) => {
    const baseOption = LABEL_OPTIONS?.find((opt) => opt.value === item.name);
    if (item.name === 'dhl') {
      const baseOptionWarenpost = LABEL_OPTIONS?.find((opt) => opt.value === "dhl_warenpost");
      return [baseOption, baseOptionWarenpost].filter(option => option); // Boş olmayanları filtrele
    }
    if (item.name === 'dhl_new') {
      const baseOptionWarenpost = LABEL_OPTIONS?.find((opt) => opt.value === "dhl_new_warenpost" && dhlAccountOptions.some(item => item?.type === 'dhl_new_warenpost'));
      return [baseOption, baseOptionWarenpost].filter(option => option); // Boş olmayanları filtrele
    }
    return baseOption;
  }).flat().filter(i => type === "return" ? i.return : i) || [];

  const INPUTS = [
    {
      name: "profile_name",
      type: "text",
      component: SVFormControl,
      label: 'SETTINGS.DOCUMENT.SHIPPING.PROFILE_NAME',
      withFeedbackLabel: true,
      feedbackLabel: true,
      required: true,
      size: "12",
      hide: true,
    },
    {
      name: 'service',
      component: DropDownControl,
      options: newOptions || [],
      placeholder: 'DOCUMENT.SHIPPING_SERVICE_PLACEHOLDER',
      noOptionsMessage: <FormattedHTMLMessage id="DOCUMENT.SHIPPING.NO_SHIPPER" values={{ url: ModuleRoutes.CONNECTIONS, a: chunks => <a href='/'>{chunks}</a> }} />,
      size: "6",
      hide: toggleLabels,
    },
    {
      name: 'service',
      type: 'text',
      component: SVReactSelect,
      options: getShipperOptions()?.filter(i => i.value !== 'dhl_new_warenpost' && i.value !== 'dhl_new') || [],
      withFeedbackLabel: true,
      feedbackLabel: true,
      required: true,
      size: "6",
      hide: !toggleLabels,
    },
    {
      name: 'type',
      component: RadioControl,
      options: SHIPPER_TYPE_OPTIONS || [],
      required: (toggleLabels && service === 'deutsche_post'),
      size: "6",
      hide: true,
    },
    {
      name: "dp_product_id",
      type: "text",
      component: SVReactSelect,
      options: deutschePost?.deutsche_post?.product_list?.map(item => ({ label: item.name, value: item.id })) || [],
      label: 'DOCUMENT.SHIPPING.DEUTSCHE_POST.PRODUCT_LIST',// "Deutsche Post Product List",
      withFeedbackLabel: true,
      feedbackLabel: true,
      required: toggleLabels && service === 'deutsche_post',
      hide: toggleLabels && service === 'deutsche_post' && currentService,
    },
    {
      name: "weight",
      type: "number",
      label: 'GENERAL.WEIGHT',
      withFeedbackLabel: true,
      feedbackLabel: true,
      required: true,
      size: "6"
    },
    {
      name: "price",
      type: "number",
      label: 'DOCUMENT.SHIPPING_PRICE',
      withFeedbackLabel: true,
      feedbackLabel: true,
      size: "6"
    },
    {
      name: "default",
      type: "checkbox",
      component: Checkbox,
      label: 'SETTINGS.DOCUMENT.SHIPPING.SET_DEFAULT',
      info: 'SETTINGS.DOCUMENT.SHIPPING.SET_DEFAULT_INFO',
      size: "12"
    }
  ];


  return (
    <Row>

      {INPUTS.filter(input => input.hide === undefined || input.hide).map(input => (
        <Col lg={input.size || "12"} key={input.name}>
          <Field component={input.component || SVFormControl}
            {...{
              name: input.name,
              type: input.type,
              label: input.label && intl({ id: input.label }),
              placeholder: input.placeholder && intl({ id: input.placeholder }),
              ...(input.noOptionsMessage && { noOptionsMessage: input.noOptionsMessage }),
              options: input.options,
              withFeedbackLabel: input.withFeedbackLabel || true,
              feedbackLabel: input.feedbackLabel || true,
              required: input.required,
              info: input.info && intl({ id: input.info }),
            }}
          />
        </Col>
      ))}

    </Row>
  );
}
