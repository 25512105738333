export default class ActionTypes {
  static get PREFIX() {
    return '[report]';
  }


  // Get report action types
  static get GET_ORDER_LIST() {
    return `${ActionTypes.PREFIX}GET_ORDER_LIST`;
  }
  static get GET_ORDER_LIST_SUCCESS() {
    return `${ActionTypes.PREFIX}GET_ORDER_LIST_SUCCESS`;
  }
  static get GET_ORDER_LIST_FAIL() {
    return `${ActionTypes.PREFIX}GET_ORDER_LIST_FAIL`;
  }

}
