import React, { useCallback, useState } from 'react';
import { ProductDeleteDialog } from './product-dialogs/ProductDeleteDialog';
import { ProductsDeleteDialog } from './product-dialogs/ProductsDeleteDialog';
import { UpdateShopsQuantityDialog } from './product-dialogs/UpdateShopsQuantityDialog';
import { ProductDetailsDialog } from './product-dialogs/ProductDetailsDialog';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { getLastSearch } from '_metronic/_helpers';
import { ProductsCard } from './ProductsCard';
import { useSearchQuery } from 'app/hooks';
import { Route } from 'react-router-dom';



export function ProductsPage() {

  const lastSearch = getLastSearch()

  const [queryParams, setQueryBase] = useState({
    pagination: {
      page: 1,
      limit: 10,
    },
    order: 'id DESC',
    search: '',
    filter: null,
  });

  const setQueryParams = useCallback((params) => {
    setQueryBase(params)
  }, [])

  const { setSearchQuery, setOrderQuery, setPaginationQuery } = useSearchQuery(setQueryParams);

  return (
    <>
      <ProductDetailsDialog queryParams={queryParams} />
      <Route path={ModuleRoutes.DELETE_PRODUCT}>
        {({ history, match }) => (
          <ProductDeleteDialog
            show={match != null}
            id={match && match.params.productId}
            onHide={() => history.push(ModuleRoutes.PRODUCT + lastSearch)}
          />
        )}
      </Route>

      <Route path={ModuleRoutes.DELETE_PRODUCT_LISTS}>
        {({ history, match }) => (
          <ProductsDeleteDialog
            show={match != null}
            ids={match && match.params.ids}
            onHide={() => history.push(ModuleRoutes.PRODUCT + lastSearch)}
            queryParams={queryParams}
          />
        )}
      </Route>

      <Route path={ModuleRoutes.UPDATE_SHOPS_QUANTITY}>
        {({ history, match }) => (
          <UpdateShopsQuantityDialog
            show={match != null}
            ids={match && match.params.ids}
            onHide={() => history.push(ModuleRoutes.PRODUCT + lastSearch)}
            queryParams={queryParams}
          />
        )}
      </Route>

      <ProductsCard queryParams={queryParams} setPaginationQuery={setPaginationQuery} setOrderQuery={setOrderQuery} setSearchQuery={setSearchQuery} />
    </>
  );
}
