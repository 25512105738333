export const defaultSorted = [
  {
    dataField: "id",
    order: "desc",
  },
];



export const getFormatterExtraEvents = (modelName, uiProps) => {
  switch (modelName) {

    case "invoices":
      return {
        openPaymentReminderDialog: uiProps.openPaymentReminderDialog,
        openEnterPaymentDialog: uiProps.openEnterPaymentDialog,
        openDocumentConvertToCredit: true,
        openDocumentConvertToWaybill: true,
        openDeleteDocumentDialog: false,
      };

    case "orders":
      return {
        openPaymentReminderDialog: uiProps.openPaymentReminderDialog,
        openDocumentConvertToWaybill: true,
        openEnterPaymentDialog: uiProps.openEnterPaymentDialog,
      };

    case "credits":
      return {
        openDeleteDocumentDialog: false,
        handleDhlLabelCreateAction: uiProps.handleDhlLabelCreateAction,
        openEnterPaymentDialog:false,
        openPaymentReminderDialog: false
      };

    case "waybills":
      return {
        openDeleteDocumentDialog: false,
        openDocumentConvertToInvoice: true,
        handleDhlLabelCreateAction: uiProps.handleDhlLabelCreateAction,
        openEnterPaymentDialog:false,
        openPaymentReminderDialog: false
      };

    case "offers":
      return {
        openDeleteDocumentDialog: uiProps.openDeleteDocumentDialog,
        handleDhlLabelCreateAction: uiProps.handleDhlLabelCreateAction,
        openDocumentConvertToInvoice: true,
        openDocumentConvertToOrder: true,
        openDocumentConvertToWaybill: true,
        openEnterPaymentDialog: false,
        openPaymentReminderDialog: false
      };

    default:
      return {};
  }
};


export const idColumnText = (documentType) => {
  const documentTypeMappings = {
    'orders': 'GENERAL.ID',
    'invoices': 'DOCUMENT.TABLE.COLUMN.INVOICE_ID',
    'credits': 'DOCUMENT.TABLE.COLUMN.CREDIT_ID',
    'waybills': 'DOCUMENT.TABLE.COLUMN.WAYBILL_ID',
    'offers': 'DOCUMENT.TABLE.COLUMN.OFFER_ID',
  };

  return documentTypeMappings[documentType] || '';
};


export const dateColumnText = (documentType) => {
  return documentType === 'orders'
    ? "DOCUMENT.DETAIL_DIALOG.ORDER.DATE"
    : documentType === 'invoices'
      ? "DOCUMENT.DETAIL_DIALOG.INVOICE.DATE"
      : "DOCUMENT.DETAIL_DIALOG.CREATION_DATE";
}
