/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Dropdown } from "react-bootstrap";
import { useDocumentsUIContext } from "../_context/DocumentsUIContext";
import { getFormatterExtraEvents } from "../document-table/columnHelpers";
import { DocumentActions } from "../_redux/actions";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from '_metronic/_icons';



export function ResponsiveActionDropdown() {

  const dispatch = useDispatch();

  // Documents UI Context
  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    openEditDocumentDialog: UIContext.openEditDocumentDialog,
    openDeleteDocumentDialog: UIContext.openDeleteDocumentDialog,
    ids: UIContext.ids,
    copyDocumentForCreateNewOne: UIContext.copyDocumentForCreateNewOne,
    requestedDocument: UIContext.requestedDocument,
    openPaymentReminderDialog: UIContext.openPaymentReminderDialog,
    openEnterPaymentDialog: UIContext.openEnterPaymentDialog,
    openPrintDocumentDialog: UIContext.openPrintDocumentDialog,
    openConvertDocumentAlertDialog: UIContext.openConvertDocumentAlertDialog,
    openConvertDocumentInfoDialog: UIContext.openConvertDocumentInfoDialog,
    pushConvertDocument: UIContext.pushConvertDocument,
    //openDHLPrintDialog: UIContext.openDHLPrintDialog,
  }), [UIContext]);

  const id = UIProps.ids.length > 0 && Object.values(UIProps.ids[0])[0];

  const dropdownEvents = {
    openEditDocumentDialog: UIProps.openEditDocumentDialog,
    copyDocumentForCreateNewOne: UIProps.copyDocumentForCreateNewOne,
    openDeleteDocumentDialog: UIProps.openDeleteDocumentDialog,
    openPaymentReminderDialog: UIProps.openPaymentReminderDialog,
    ...getFormatterExtraEvents(UIProps.requestedDocument.type, UIProps),
  };

  const openPrintDocumentDialog = (id) => {
    if (id) {
      const data = [{
        id: [id],
        type: UIProps.requestedDocument.type,
      }];
      dispatch(DocumentActions.printDocument(data));
      UIProps.openPrintDocumentDialog();
    }
  };

  const documentConvertHandler = (id, from, to, isDuplicated, isDuplicateInvoiceId) => {
    if (from === "waybills" && isDuplicateInvoiceId) {
      return UIProps.openConvertDocumentInfoDialog({ isDuplicateInvoiceId });
    }
    if (isDuplicated) {
      return UIProps.openConvertDocumentAlertDialog({ id, from, to });
    }
    UIProps.pushConvertDocument(id, from, to);
  };

  const documents = useSelector(state => state.documents.documents);
  const foundDocument = documents.find(document => document.id === id);

  const isConvert = (action, type) => foundDocument?.history?.find(history => history.action === action && history.type === type)?.relation_id ?? false;

  return (
    <Dropdown className="dropdown-inline d-sm-none" drop="down">

      <Dropdown.Toggle className="btn svg-icon svg-icon-sm btn-light-primary font-weight-bold dropdown-toggle ml-2 mb-2" id="dropdown-basic" disabled={UIProps.ids.length === 1 ? false : true}>
        <Icon.List />
        {/* <FormattedMessage id="DOCUMENT.ACTION.CONTENT" /> */}
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu dropdown-menu-md dropdown-menu-right" id='dropdown_document_action'>
        <ul className="navi navi-hover">

          {/* {dropdownEvents.openDHLPrintDialog && (
            <li className="navi-item">
              <Dropdown.Item className="navi-link" onClick={() => dropdownEvents.openDHLPrintDialog(id, "dhl")}>
                  <Icon.Box />
                <span className="navi-text">
                  <FormattedMessage id="DOCUMENT.ACTION.LABEL" values={{ shippingName: 'dhl' }} />
                </span>
              </Dropdown.Item>
            </li>
          )}

          {dropdownEvents.openDHLPrintDialog && (
            <li className="navi-item">
              <Dropdown.Item className="navi-link" onClick={() => dropdownEvents.openDHLPrintDialog(id, "gls")}>
                  <Icon.Box />
                <span className="navi-text">
                  <FormattedMessage id="DOCUMENT.ACTION.LABEL" values={{ shippingName: 'gls' }} />
                </span>
              </Dropdown.Item>
            </li>
          )} */}

          <li className="navi-item">
            <Dropdown.Item className="navi-link" onClick={() => openPrintDocumentDialog(id)} id={`dropdown_item_print_${id}`}>
              <span className="svg-icon svg-icon-md mr-2">
                <Icon.Printer />
              </span>
              <span className="navi-text text-nowrap font-weight-bold">
                <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.PRINT' values={{ documentTitle: UIProps.requestedDocument.title }} />
              </span>
            </Dropdown.Item>
          </li>

          <li className="navi-item">
            <Dropdown.Item className="navi-link" onClick={() => dropdownEvents.openEditDocumentDialog(id)} id={`dropdown_item_edit_${id}`}>
              <span className="svg-icon svg-icon-md mr-2">
                <Icon.PencilSquare />
              </span>
              <span className="navi-text text-nowrap font-weight-bold">
                <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.EDIT' values={{ documentTitle: UIProps.requestedDocument.title }} />
              </span>
            </Dropdown.Item>
          </li>

          <li className="navi-item">
            <Dropdown.Item className="navi-link" onClick={() => dropdownEvents.copyDocumentForCreateNewOne(id)} id={`dropdown_item_copy_${id}`}>
              <span className="svg-icon svg-icon-md mr-2">
                <Icon.Copy />
              </span>
              <span className="navi-text text-nowrap font-weight-bold">
                <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.COPY' values={{ documentTitle: UIProps.requestedDocument.title }} />
              </span>
            </Dropdown.Item>
          </li>

          {dropdownEvents.openDocumentConvertToCredit && (
            <li className="navi-item">
              <Dropdown.Item className="navi-link" onClick={() => documentConvertHandler(id, UIProps.requestedDocument.type, 'credits', isConvert('convert', 'credit'))} id={`dropdown_item_credit"_${id}`}>
                <span className="svg-icon svg-icon-md mr-2">
                  <Icon.FileEarmarkText />
                </span>
                <span className="navi-text text-nowrap font-weight-bold">
                  <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.CREDIT_INVOICE' />
                </span>
              </Dropdown.Item>
            </li>
          )}

          {dropdownEvents.openDocumentConvertToWaybill && (
            <li className="navi-item">
              <Dropdown.Item className="navi-link" onClick={() => documentConvertHandler(id, UIProps.requestedDocument.type, 'waybills', isConvert('convert', 'waybill'))} id={`dropdown_item_waybill"_${id}`}>
                <span className="svg-icon svg-icon-md mr-2">
                  <Icon.FileEarmarkText />
                </span>
                <span className="navi-text text-nowrap font-weight-bold">
                  <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.DELIVERY' values={{ documentTitle: UIProps.requestedDocument.title }} />
                </span>
              </Dropdown.Item>
            </li>
          )}

          {dropdownEvents.openDocumentConvertToInvoice && (
            <li className="navi-item">
              <Dropdown.Item className="navi-link" onClick={() => documentConvertHandler(id, UIProps.requestedDocument.type, 'invoices', isConvert('convert', 'invoice'), isConvert('create', 'invoice'))} id={`dropdown_item_fulfillment_${id}`}>
                <span className="svg-icon svg-icon-md mr-2">
                  <Icon.FileEarmarkText />
                </span>
                <span className="navi-text text-nowrap font-weight-bold">
                  <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.INVOICE' values={{ documentTitle: UIProps.requestedDocument.title }} />
                </span>
              </Dropdown.Item>
            </li>
          )}

          {dropdownEvents.openDocumentConvertToOrder && (
            <li className="navi-item">
              <Dropdown.Item className="navi-link" onClick={() => documentConvertHandler(id, UIProps.requestedDocument.type, 'orders', isConvert('convert', 'order'))} id={`dropdown_item_order_${id}`}>
                <span className="svg-icon svg-icon-md mr-2">
                  <Icon.FileEarmarkText />
                </span>
                <span className="navi-text text-nowrap font-weight-bold">
                  <FormattedMessage id='DOCUMENT.COLUMN_FORMATTER.ORDER' values={{ documentTitle: UIProps.requestedDocument.title }} />
                </span>
              </Dropdown.Item>
            </li>
          )}

          {dropdownEvents.openEnterPaymentDialog && (
            <li className="navi-item">
              <Dropdown.Item className="navi-link" onClick={() => dropdownEvents.openEnterPaymentDialog(id)} id={`dropdown_item_credit_${id}`}>
                <span className="svg-icon svg-icon-md mr-2">
                  <Icon.CreditCard />
                </span>
                <span className="navi-text text-nowrap font-weight-bold">
                  <FormattedMessage id="DOCUMENT.ACTION.PAYMENTS" />
                </span>
              </Dropdown.Item>
            </li>
          )}

          {dropdownEvents.openPaymentReminderDialog && (
            <>
              <li className="navi-item">
                <Dropdown.Item className="navi-link" onClick={() => dropdownEvents.openPaymentReminderDialog(id, "reminder")} id={`dropdown_item_reminder_${id}`}>
                  <span className="svg-icon svg-icon-md mr-2">
                    <Icon.Bell />
                  </span>
                  <span className="navi-text text-nowrap font-weight-bold">
                    <FormattedMessage id="DOCUMENT.ACTION.PAYMENT_REMINDER" />
                  </span>
                </Dropdown.Item>
              </li>

              <li className="navi-item">
                <Dropdown.Item className="navi-link" onClick={() => dropdownEvents.openPaymentReminderDialog(id, "warning_1")} id={`dropdown_item_warning1_${id}`}>
                  <span className="svg-icon svg-icon-md mr-2">
                    <Icon.AppIndicator />
                  </span>
                  <span className="navi-text text-nowrap font-weight-bold">
                    <FormattedMessage id="DOCUMENT.ACTION.REMINDER1" />
                  </span>
                </Dropdown.Item>
              </li>

              <li className="navi-item">
                <Dropdown.Item className="navi-link" onClick={() => dropdownEvents.openPaymentReminderDialog(id, "warning_2")} id={`dropdown_item_warning2_${id}`}>
                  <span className="svg-icon svg-icon-md mr-2">
                    <Icon.AppIndicator />
                  </span>
                  <span className="navi-text text-nowrap font-weight-bold">
                    <FormattedMessage id="DOCUMENT.ACTION.REMINDER2" />
                  </span>
                </Dropdown.Item>
              </li>
            </>
          )}

          {dropdownEvents.openDeleteDocumentDialog && (
            <>
              <li className="navi-separator my-1" />
              <li className="navi-item">
                <Dropdown.Item className="navi-link" onClick={() => dropdownEvents.openDeleteDocumentDialog([id])} id={`dropdown_item_archive_${id}`}>
                  <span className="svg-icon svg-icon-md mr-2">
                    <Icon.Archive />
                  </span>
                  <span className="navi-text text-nowrap font-weight-bold">
                    <FormattedMessage id="DOCUMENT.COLUMN_FORMATTER.SEND_TO_ARCHIVE" />
                  </span>
                </Dropdown.Item>
              </li>
            </>
          )}

        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}
