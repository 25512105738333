import { call, put, select, takeEvery } from "redux-saga/effects";
import { reportMiddleware } from "middlewares";
import { ReportActions } from "./actions";
import ActionTypes from "./actionTypes";



// Get Order list Saga
function* getOrderList({ payload: { dateRange } }) {
  try {
    const accessToken = yield select(state => state.auth.access_token);

    const response = yield call(reportMiddleware.getOrderList, accessToken, dateRange);
    yield put(ReportActions.getOrderListSuccess(response.data));

  } catch (err) {

    yield put(ReportActions.getOrderListFail(err));
  }
}


export function* reportSaga() {
  yield takeEvery(ActionTypes.GET_ORDER_LIST, getOrderList);
}
