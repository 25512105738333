import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { userGuides } from './guides/index';
import { UserGuidesDropdown } from './UserGuidesDropdown';




export function UserGuideHelper({ title }) {

  const [guide, setGuide] = useState({
    title: '',
    description: '',
  });

  const { pathname } = useLocation();

  useEffect(() => {
    const pathNames = pathname.split('/');
    const lastPath = pathNames[pathNames.length - 1];
    let findGuide, subGuide;

    if (pathNames[1] === 'settings') {
      findGuide = userGuides.find(guide => guide.main.location === pathNames[1]);
      subGuide = lastPath === pathNames[1] ? findGuide?.main : findGuide?.[lastPath];
    } else if (pathNames[1] === 'listings') {
      findGuide = userGuides.find(guide => guide.main.location === pathNames[1]);
      subGuide = lastPath === pathNames[1] ? findGuide?.main : findGuide?.[pathNames[2]];
    } else if (pathNames[1] === 'connections' && pathNames[2] === 'add') {
      findGuide = userGuides.find(guide => guide.main.location === pathNames[2]);
      subGuide = findGuide && findGuide.main;
    } else if (pathNames[1] === 'connections') {
      findGuide = userGuides.find(guide => guide.main.location === 'connections');
      subGuide = findGuide && findGuide.main;
    } else {
      for (const pathName of pathNames) {
        findGuide = userGuides.find(guide => guide.main.location === pathName);
        subGuide = lastPath === pathName ? findGuide?.main : findGuide?.[lastPath];
        if (subGuide) break;
      }
    }

    subGuide && setGuide(subGuide);
  }, [pathname]);

  return (
    guide.description
      ? <UserGuidesDropdown userGuide={guide} values={title} />
      : null
  )
}

