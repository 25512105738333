import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Card, CardBody, CardHeader, CardHeaderToolbar } from '_metronic/_partials/controls';
import { UserGuideHelper } from '../../user-guides/UserGuideHelper';
import { ProductsFilter } from './product-filter/ProductsFilter';
import { ProductsTable } from './product-table/ProductsTable';
import { BlankMessage } from '_metronic/_partials/components';
import { ModuleRoutes } from 'constants/moduleRoutes';
import { ProductActions } from './_redux/actions';
import { useGetProductsEffect } from 'app/hooks';
import { NewButton } from '_metronic/_partials';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function ProductsCard({ setSearchQuery, queryParams, setPaginationQuery, setOrderQuery }) {

  // get list of products by search query params
  useGetProductsEffect(queryParams, ProductActions.getProducts);

  const { entities, loading, countProducts, totalProducts } = useSelector(state => state.products, shallowEqual);

  return (
    <Card className="card-height">

      <CardHeader
        icon={<Icon.Catalog />}
        title={<FormattedMessage id="PRODUCT.TITLE_PLURAL" />}
        info={<UserGuideHelper />}
        className="display-5"
        id="title_product"
      >
        <CardHeaderToolbar>
          <NewButton link={ModuleRoutes.NEW_PRODUCT} label="PRODUCT.PRODUCT_NEW" />
        </CardHeaderToolbar>
      </CardHeader>

      <CardBody>
        {totalProducts === 0
          ? <BlankMessage
            icon={<Icon.Catalog />}
            title={<FormattedMessage id='PRODUCT.BLANK_MESSAGE_TITLE' />}
            message={<FormattedMessage id='PRODUCT.BLANK_MESSAGE' />}
            className='min-50-height'
            loading={loading}
            arrow />
          : <>
            <ProductsFilter setSearchQuery={setSearchQuery} />
            <ProductsTable
              queryParams={queryParams}
              setPaginationQuery={setPaginationQuery}
              setOrderQuery={setOrderQuery}
              entities={entities}
              loading={loading}
              countProducts={countProducts}
              setSearchQuery={setSearchQuery}
            />
          </>
        }
      </CardBody>
    </Card>
  );
}
