import React from "react";
import { Formik, Form, Field } from "formik";
import { Button, Col, Modal, Row } from 'react-bootstrap';
import { SVReactSelect, SVFormControl, RadioControl } from "_metronic/_partials/controls";
import { streetRegExp, nameRegExp, plzRegExp, getCountryList, addressType, maxValidation, salutation } from '_metronic/_helpers';
import { FormattedMessage, useIntl } from "react-intl";
import { useLang } from "_metronic/i18n";
import * as Yup from "yup";



export function AddressEditForm({ handleSubmit, address, onHide }) {

  const locale = useLang();
  const { formatMessage: intl } = useIntl();

  const AddressEditSchema = Yup.object().shape({
    type: Yup.string()
      .required(intl({ id: "ADDRESS.TYPE_REQUIRED" })),
    salutation: Yup.string(),
    name: Yup.string()
      .max(50, maxValidation("50"))
      .matches(nameRegExp, intl({ id: "ADDRESS.NAME_MATCHES" }))
      /* .when('company', {
        is: company => company === undefined,
        then: Yup.string().required(intl({ id:"ADDRESS.NAME_REQUIRED2" })),
      }) */
      .required(intl({ id: "GENERAL.NAME_REQUIRED" })),
    company: Yup.string()
      .max(100, maxValidation("100"))
      .matches(nameRegExp, intl({ id: "ADDRESS.COMPANY_MATCHES" })),
    address_line1: Yup.string()
      .max(150, maxValidation("150"))
      .required(intl({ id: "ADDRESS.STREET_REQUIRED" }))
      .when('country_code', {
        is: 'DE',  // Sadece country_code 'DE' olduğunda matches kontrolü yap
        then: schema => schema.matches(streetRegExp, intl({ id: "ADDRESS.STREET_MATCHES" })),
        otherwise: schema => schema,  // Diğer durumlarda matches kontrolü olmadan devam et
      }),
    address_line2: Yup.string()
      .max(150, maxValidation("150")),
    post_code: Yup.string()
      .max(10, intl({ id: "ADDRESS.MAX_CHAR" }, { name: "10" }))
      .when('country_code', {
        is: 'DE', // only apply the matches validation when country_code is 'DE'
        then: Yup.string().matches(plzRegExp, intl({ id: "ADDRESS.PLZ_MATCHES" }))
      })
      .required(intl({ id: "ADDRESS.POSTCODE_REQUIRED" }))
      .typeError(intl({ id: "ADDRESS.PLZ_TYP" })),
    city: Yup.string()
      .max(50, intl({ id: "ADDRESS.MAX_CHAR" }, { name: "50" }))
      // .matches(ortRegExp, intl({ id: "ADDRESS.LOCATION_MATCHES" }))
      .required(intl({ id: "ADDRESS.LOCATION_REQUIRED" })),
    country_code: Yup.string()
      .required(intl({ id: "ADDRESS.COUNTRY_REQUIRED" })),
  });


  const INPUTS = [
    {
      name: "type",
      label: "ADDRESS.TYPE",
      component: RadioControl,
      options: addressType,
      required: true,
    },
    {
      name: "salutation",
      label: "ADDRESS.SALUTATION",
      component: RadioControl,
      options: salutation,
    },
    {
      name: "name",
      label: "GENERAL.NAME",
      required: true,
    },
    {
      name: "company",
      label: "GENERAL.COMPANY",
    },
    {
      name: "address_line1",
      label: "ADDRESS.STREET",
      required: true,
    },
    {
      name: "address_line2",
      label: "ADDRESS.SUFFIX",
    },
    {
      name: "post_code",
      label: "ADDRESS.PLZ",
      size: "4",
      required: true,
    },
    {
      name: "city",
      label: "ADDRESS.CITY",
      size: "4",
      required: true,
    },
    {
      name: "country_code",
      label: "ADDRESS.COUNTRY",
      component: SVReactSelect,
      options: getCountryList(locale),
      size: "4",
      required: true,
    }
  ];


  return (
    <Formik enableReinitialize={true} initialValues={address} validationSchema={AddressEditSchema} onSubmit={handleSubmit}>
      <Form>

        <Modal.Body>
          <Row>
            {INPUTS.map((item, i) => (
              <Col lg={item.size ?? "6"} key={i}>
                <Field component={item.component || SVFormControl}
                  {...{
                    name: item.name,
                    label: intl({ id: item.label }),
                    withFeedbackLabel: false,
                    options: item.options,
                    feedbackLabel: true,
                    required: item.required,
                  }}
                />
              </Col>
            ))}
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="light" onClick={onHide} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CANCEL" />
          </Button>

          <Button variant='primary' type="submit" className="ml-2" id="btn_add_modal">
            <FormattedMessage id="GENERAL.ADD" />
          </Button>
        </Modal.Footer>

      </Form>
    </Formik>
  );
}