import React from "react";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import '@ckeditor/ckeditor5-build-classic/build/translations/de';
import { useLang } from "_metronic/i18n";
import { SVFeedbackLabelBottom, SVFormControlLabel, SVFormControlWrapper } from "./sv-ui/SVFormHelpers";
import UserInfoDropdown from "_metronic/_partials/user-info/UserInfoDropdown";



export function CkEditor({
  data,
  editor = ClassicEditor,
  onInit,
  onChange,
  onBlur = (e, editor) => { },
  onFocus,
  language,
  ...props
}) {

  const lang = useLang();

  return (
    <CKEditor
      editor={editor}
      data={data}
      config={{
        language: lang,
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "blockQuote",
          "link",
          "numberedList",
          "bulletedList",
          "insertTable",
          /*"tableColumn",*/
          /*"tableRow",*/
          /*"mergeTableCells",*/
          "|",
          /*'imageUpload', */
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
      }}
      onInit={onInit}
      onChange={onChange}
      onBlur={onBlur}
      onFocus={onFocus}
      {...props}
    />
  );
}




export function CkEditorFormControl({
  data,
  label,
  field,
  form: { touched, errors },
  editor = ClassicEditor,
  onInit,
  onChange,
  onBlur = (e, editor) => { },
  onFocus,
  language,
  required,
  info,
  feedbackLabel,
  ...props
}) {

  const lang = useLang();

  const fieldCheck = (type, field) => {
    if (field.includes(".")) {
      return type[field.split(".")[0]]?.[field.split(".")[1]];
    } else {
      return type[field];
    }
  };


  return (
    <SVFormControlWrapper>

      {label &&
        <SVFormControlLabel errors={fieldCheck(touched, field.name) && (fieldCheck(errors, field.name) || typeof feedbackLabel === "string") && true}>
          {label} {required && <span className="text-danger">*</span>}
          {info && <UserInfoDropdown size="w-75" title={label} description={info} />}
        </SVFormControlLabel>
      }

      <CKEditor
        editor={editor}
        data={data}
        config={{
          language: lang,
          toolbar: [
            "heading",
            "|",
            "bold",
            "italic",
            "blockQuote",
            "link",
            "numberedList",
            "bulletedList",
            "insertTable",
            /*"tableColumn",*/
            /*"tableRow",*/
            /*"mergeTableCells",*/
            "|",
            /*'imageUpload',*/
            /*"mediaEmbed",*/
            "undo",
            "redo",
          ],
        }}
        onInit={onInit}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
        {...props}
      />

      {feedbackLabel &&
        <SVFeedbackLabelBottom withFeedbackLabel={true} errors={fieldCheck(touched, field.name) && fieldCheck(errors, field.name) && true} label={label}>
          {fieldCheck(touched, field.name) && fieldCheck(errors, field.name) ? fieldCheck(errors, field.name) : <span className='text-danger'>{feedbackLabel}</span>}
        </SVFeedbackLabelBottom>
      }

    </SVFormControlWrapper>
  );
}
