import React from 'react';
import { Card, CardBody, CardSubHeader } from '_metronic/_partials/controls';
import { getCountryList } from '_metronic/_helpers';
import { CardItems } from '_metronic/_partials/components';
import { UserInfoDropdown } from '_metronic/_partials';
import { useLang } from '_metronic/i18n/Metronici18n';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';



export function AddressCard({ companyBaseData }) {

  const locale = useLang();

  const ADDRESS_INFO = [
    {
      label: 'GENERAL.COMPANY_NAME',
      content: companyBaseData?.company_name,
    },
    {
      label: 'GENERAL.OWNER',
      content: companyBaseData?.owner,
    },
    {
      label: 'GENERAL.EMAIL',
      content: companyBaseData?.email,
    },
    {
      label: 'GENERAL.PHONE',
      content: companyBaseData?.phone,
    },
    {
      label: 'GENERAL.MOBILE',
      content: companyBaseData?.mobile,
    },
    {
      label: 'GENERAL.WEB',
      content: companyBaseData?.web,
    },
    {
      label: 'GENERAL.FAX',
      content: companyBaseData?.fax,
    },
    {
      label: 'ADDRESS.STREET1',
      content: companyBaseData?.address?.address_line1,
    },
    {
      label: 'ADDRESS.PLZ',
      content: companyBaseData?.address?.post_code,
    },
    {
      label: 'ADDRESS.CITY',
      content: companyBaseData?.address?.city,
    },
    {
      label: 'ADDRESS.COUNTRY',
      content: getCountryList(locale).find(code => code.value === companyBaseData?.address?.country_code)?.label,
    },
  ]


  return (
    <Card className="card-stretch gutter-b">

      <CardSubHeader
        icon={<Icon.GeoAlt />}
        title={<FormattedMessage id="COMPANY.ADDRESS_TITLE" />}
        info={<UserInfoDropdown
          title={<FormattedMessage id='COMPANY.ADDRESS_TITLE' />}
          description={<FormattedMessage id='USER_GUIDE.SETTINGS.COMPANY_ADDRESS' />} />}
      />

      <CardBody>
        <CardItems Content={ADDRESS_INFO} />
      </CardBody>

    </Card>
  );
}
