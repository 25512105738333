/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useArchiveUIContext } from './_context/ArchiveUIContext';
import { Dropdown, Form } from 'react-bootstrap';
import { theme, UIDates } from '_metronic/_helpers';
import { Icon } from '_metronic/_icons';
import moment from 'moment';



export function DatePickerDropdown({ setFilterQuery }) {

  const { formatMessage: intl } = useIntl()

  const UIContext = useArchiveUIContext();
  const UIProps = useMemo(() => ({
    changeDateRange: UIContext.changeDateRange,
    dateRange: UIContext.dateRange,
    setDateRange: UIContext.setDateRange,
    initialDateRange: UIContext.initialDateRange
  }), [UIContext]);

  const today = UIDates.getCurrentDate()

  const [customDateRange, setCustomDateRange] = useState({
    date_from: moment().add(-30, "d").format("YYYY-MM-DD"),
    date_to: today
  })

  const optionData = [
    {
      id: "todays",
      label: intl({ id: "DASHBOARD.DATE.TODAY" }),
      startDate: today,
      endDate: today
    },
    {
      id: "last-7-days",
      label: intl({ id: "DASHBOARD.DATE.LAST_7_DAYS" }),
      startDate: UIDates.dayCalculation(-7),
      endDate: today
    },
    {
      id: "last-14-days",
      label: intl({ id: "DASHBOARD.DATE.LAST_14_DAYS" }),
      startDate: UIDates.dayCalculation(-14),
      endDate: today
    },
    {
      id: "last-30-days",
      label: intl({ id: "DASHBOARD.DATE.LAST_30_DAYS" }),
      startDate: UIDates.dayCalculation(-30),
      endDate: today
    },
    {
      id: "last-90-days",
      label: intl({ id: "DASHBOARD.DATE.LAST_90_DAYS" }),
      startDate: UIDates.dayCalculation(-90),
      endDate: today
    },
    {
      id: "this-month",
      label: intl({ id: "DASHBOARD.DATE.THIS_MONT" }),
      startDate: UIDates.getCurrentDate("YYYY-MM-01"),
      endDate: today
    },
    {
      id: "last-month",
      label: intl({ id: "DASHBOARD.DATE.LAST_MONT" }),
      startDate: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD"),
      endDate: moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD"),
    },
    {
      id: "this-year",
      label: intl({ id: "DASHBOARD.DATE.THIS_YEAR" }),
      startDate: UIDates.getCurrentDate("YYYY-01-01"),
      endDate: today
    },
  ]

  const dateChanger = (e) => {

    const selectedDate = optionData.find(item => item.id === e.target.id);

    if (e.target.id === "clear-date") {
      UIProps.setDateRange({
        id: e.target.id,
        label: intl({ id: "GENERAL.CLEAR" }),
      });
      setFilterQuery('DELETE_PARAMS', ['date_from', 'date_to', 'doc_type']);
    } else {
      UIProps.setDateRange({
        ...UIProps.dateRange,
        id: selectedDate.id,
        label: selectedDate.label,
        startDate: selectedDate.startDate,
        endDate: selectedDate.endDate
      });
      setFilterQuery('DATE_RANGE', [selectedDate.startDate, selectedDate.endDate])
    }
  };

  const dateChangerCustom = (e) => {
    setCustomDateRange({ ...customDateRange, [e.target.name]: e.target.value })
    setFilterQuery(e.target.name, e.target.value);
  }


  const handleSubmit = (e) => {
    e.preventDefault();

    UIProps.setDateRange({
      ...UIProps.dateRange,
      label: "Custom Range",
      startDate: customDateRange.date_from,
      endDate: customDateRange.date_to
    })
  }


  return (
    <Dropdown drop="down" className='dropdown-inline mr-3'>

      <Dropdown.Toggle variant='outline-secondary' className='d-flex align-items-center svg-icon svg-icon-sm font-weight-bold' style={{ height: theme.units.input.height['sm'] }} id="btn_date_picker">
        <Icon.Calendar className="mr-3" />
        {UIProps.dateRange?.id === 'clear-date' ? <FormattedMessage id='GENERAL.DATE_FILTER' /> : UIProps.dateRange?.label}
      </Dropdown.Toggle>

      {UIProps.dateRange?.startDate && <i className="symbol-badge bg-danger"></i>}

      <Dropdown.Menu className="dropdown-menu dropdown-menu-right dropdown-menu-anim-up" id='dropdown_date_picker'>
        <Form onSubmit={handleSubmit}>
          <ul className="navi navi-hover">
            {optionData.map((item, i) => (
              <li className="navi-item" key={i}>
                <Dropdown.Item className='navi-link font-weight-bold' id={item.id} onClick={dateChanger}>
                  {item.label}
                </Dropdown.Item>
              </li>
            ))}

            <li className="navi-item">
              <a className='dropdown-item navi-link font-weight-bolder' href="#" id="custom" onClick={(e) => UIProps.setDateRange({ ...UIProps.dateRange, id: e.target.id, })}>
                <FormattedMessage id='DASHBOARD.SET_CUSTOM_RANGE' />
              </a>

              {UIProps.dateRange.id === "custom" && <div className='m-3'>

                <div className='d-flex align-items-center flex-row flex-nowrap mb-3'>

                  <Form.Control name="date_from" type="date" value={customDateRange?.date_from} min={"1990-01-01"} max={UIProps?.dateRange.endDate || today}
                    onChange={dateChangerCustom} disabled={UIProps.dateRange.id !== "custom"} />
                  <b className='mx-2'>to</b>
                  <Form.Control name="date_to" type="date" value={customDateRange?.date_to} max={today}
                    onChange={dateChangerCustom} disabled={UIProps?.dateRange.id !== "custom"} />

                  <Dropdown.Item className='btn btn-light-primary font-weight-bold ml-2' onClick={handleSubmit} id="dropdown_item_save">
                    <FormattedMessage id='GENERAL.SAVE' />
                  </Dropdown.Item>

                </div>

              </div>}
            </li>

            <Dropdown.Divider />
            <li className="navi-item">
              <Dropdown.Item className='navi-link font-weight-bolder text-danger justify-content-center bg-white py-1' id='clear-date' onClick={dateChanger}>
                <FormattedMessage id='GENERAL.CLEAR' />
              </Dropdown.Item>
            </li>

          </ul>
        </Form>
      </Dropdown.Menu>

    </Dropdown>
  );
}
