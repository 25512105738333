import React, { useMemo, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useDocumentsUIContext } from '../_context/DocumentsUIContext';
import { DocumentActions } from '../_redux/actions';
import { FormattedMessage } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { SelectCounter } from '_metronic/_partials';
import { Portal } from 'react-portal';



export function SendEmailsDialog() {

  const UIContext = useDocumentsUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    showSendEmailsDialog: UIContext.showSendEmailsDialog,
    handleCloseSendEmailsDialog: UIContext.handleCloseSendEmailsDialog,
    templates: UIContext.templates,
    requestedDocument: UIContext.requestedDocument,
    reminder: UIContext.reminder,
  }),
    [UIContext]
  );

  const { printLoading, error, emailSuccess } = useSelector(state => ({
    printLoading: state.documents.printLoading,
    emailSuccess: state.documents.emailSuccess,
    error: state.documents.error,
  }),
    shallowEqual
  );

  const [selectedIds, setSelectedIds] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!UIProps.showSendEmailsDialog || !(UIProps.ids.length > 0)) {
      return UIProps.handleCloseSendEmailsDialog();
    }
    const ids = [];
    UIProps.ids.map(id => Object.values(id).forEach(id => ids.push(id)));
    setSelectedIds(ids);
  }, [dispatch, UIProps, UIProps.ids]);

  const sendSelectedDocumentWithEmail = selIds => {
    if (selIds.length) {
      const data = selIds.map(id => ({
        id: id,
        type: UIProps.requestedDocument.type,
        isEmail: true,
        ...(UIProps.reminder && { reminder: UIProps.reminder })
      }));

      dispatch(DocumentActions.printDocument(data));
    }
    // UIProps.handleCloseSendEmailsDialog();
  };

  useEffect(() => {
    if (emailSuccess) {
      setTimeout(() => {
        UIProps.handleCloseSendEmailsDialog()
        dispatch(DocumentActions.clearLoadingEffects());
        UIProps.setIds([]);
        setSelectedIds([]);
      }, 2500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailSuccess, UIProps.ids]);


  return (
    <Portal node={document && document.getElementById("modal-portal")}>
      <Modal show={UIProps.showSendEmailsDialog} onHide={UIProps.handleCloseSendEmailsDialog} aria-labelledby="example-modal-sizes-title-standard" centered>

        <Modal.Body className='p-12'>

          <div className="d-flex flex-column align-items-center">
            {emailSuccess
              ? <Icon.CheckCircle className="text-success icon-6x my-5" />
              : <Icon.EnvelopeAt className="text-dark-75 icon-5x my-6" />
            }
          </div>

          <h5 className="text-center font-weight-bold">
            {emailSuccess ?
              <FormattedMessage id='DOCUMENT.REDUX.SEND_SELECTED_DOCUMENT_WITH_EMAIL.SUCCESS' />
              : error
                ? <span className="text-danger">
                  {error?.message ? error.message : <FormattedMessage id='GENERAL.ERROR_MESSAGE' />}
                </span>
                : <FormattedMessage id="DOCUMENT.EMAIL_SEND.CONTENT" />
            }
          </h5>
        </Modal.Body>

        <Modal.Footer>

          <SelectCounter count={UIProps.ids.length} />

          <Button variant='light' onClick={UIProps.handleCloseSendEmailsDialog} disabled={printLoading} id="btn_cancel_modal">
            <FormattedMessage id="GENERAL.CLOSE" />
          </Button>

          {!emailSuccess &&
            <Button variant='primary' className='ml-2' onClick={() => sendSelectedDocumentWithEmail(selectedIds)} disabled={printLoading} id="btn_send_email">
              <FormattedMessage id="GENERAL.SEND" />
            </Button>}

        </Modal.Footer>

      </Modal>
    </Portal>
  );
}
