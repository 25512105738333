import React from 'react';
import { Card, CardBody, CardSubHeader, SVFormTextarea } from '_metronic/_partials/controls';
import { FormattedMessage, useIntl } from 'react-intl';
import { UserInfoDropdown } from '_metronic/_partials';
import { Col, Row } from 'react-bootstrap';
import { Icon } from '_metronic/_icons';
import { Field } from 'formik';



const INPUTS = [
  {
    name: "subtitle.header",
    label: "GENERAL.HEADER",
    size: 6,
  },
  {
    name: "subtitle.footer",
    label: "GENERAL.FOOTER",
    size: 6,
  },
  {
    name: "notes",
    label: "DOCUMENT.HEADER_COURSE",
  },
];


export function TextCard({ documentId, loading }) {

  const { formatMessage: intl } = useIntl();

  return (
    <Card>

      <CardSubHeader
        icon={<Icon.TextIndentLeft />}
        title={<FormattedMessage id="GENERAL.HEADER&FOOTER" />}
        info={<UserInfoDropdown
          title={<FormattedMessage id={'DOCUMENT.HEADER.INFO_TITLE'} />}
          description={<FormattedMessage id={'DOCUMENT.HEADER.INFO_DESCRIPTION'} />} />}
      />

      <CardBody>
        <Row>

          {INPUTS.map((item, i) => (!(item.name === "notes" && !documentId) &&
            <Col lg={item.size || 12} key={i}>
              <Field component={SVFormTextarea}
                {...{
                  name: item.name,
                  label: intl({ id: item.label }),
                  placeholder: item.placeholder,
                  rows: 6,
                  maxlength: 10,
                  withFeedbackLabel: true,
                  feedbackLabel: true,
                  disabled: loading,
                }}
              />
            </Col>
          ))}
        </Row>

      </CardBody>
    </Card>
  );
}
