import ActionTypes from './actionTypes';



const INITIAL_STATE = {
  loading: false,
  entities: [],
  countTickets: 0,
  totalTickets: 0,
  unreadTickets: 0,
  ticket: {},
  ticketImages: [],
  error: '',
  success: false,
};


export function supportsReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {


    // Get Tickets
    case ActionTypes.GET_TICKETS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_TICKETS_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: payload.tickets,
        countTickets: payload.count.count,
        totalTickets: payload.count.total,
        unreadTickets: payload.count.unread,
      };

    case ActionTypes.GET_TICKETS_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Create Ticket
    case ActionTypes.CREATE_TICKET:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case ActionTypes.CREATE_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        entities: [
          payload.ticket,
          ...state.entities,
        ],
        countTickets: state.countTickets + 1,
        totalTickets: state.totalTickets + 1,
      };

    case ActionTypes.CREATE_TICKET_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Pictures Handler
    case ActionTypes.PICTURES_HANDLER:
      return {
        ...state,
        ticketImages: payload.image,
      };



    // Update Ticket
    case ActionTypes.UPDATE_TICKET:
      return {
        ...state,
        loading: true,
        success: false,
      };

    case ActionTypes.UPDATE_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };

    case ActionTypes.UPDATE_TICKET_FAIL:
      return {
        ...state,
        loading: false,
        success: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Ticket Status
    case ActionTypes.TICKET_STATUS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.TICKET_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: state.entities.map((ticket) => ticket.id === payload.id ? payload.updatedTicket : ticket),
        ticket: payload.updatedTicket,
      };

    case ActionTypes.TICKET_STATUS_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Ticket PROPS
    case ActionTypes.TICKET_PROPS:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.TICKET_PROPS_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: state.entities.map((ticket) => ticket.id === payload.id ? payload.updatedTicket : ticket),
        ticket: payload.updatedTicket,
      };

    case ActionTypes.TICKET_PROPS_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Count Tickets
    case ActionTypes.COUNT_TICKETS:
      return state;

    case ActionTypes.COUNT_TICKETS_SUCCESS:
      return {
        ...state,
        unreadTickets: payload.count,
      };

    case ActionTypes.COUNT_TICKETS_FAIL:
      return state;



    // Delete Category
    case ActionTypes.DELETE_TICKET:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.DELETE_TICKET_SUCCESS:
      return {
        ...state,
        loading: false,
        entities: state.entities.filter((ticket) => ticket.id !== payload.id),
        countTickets: state.countTickets - 1,
        totalTickets: state.totalTickets - 1,
      };

    case ActionTypes.DELETE_TICKET_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Get Ticket by id
    case ActionTypes.GET_TICKET_BY_ID:
      return {
        ...state,
        loading: true,
      };

    case ActionTypes.GET_TICKET_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        ticket: payload.ticket,
      };

    case ActionTypes.GET_TICKET_BY_ID_FAIL:
      return {
        ...state,
        loading: false,
        error: "GENERAL.ERROR_MESSAGE_GENERAL",
      };



    // Clean Up Images
    case ActionTypes.CLEAN_UP_IMAGES:
      return {
        ...state,
        loading: false,
        ticketImages: [],
        error: '',
        success: false,
      };



    // Clean Up Reducer
    case ActionTypes.CLEAN_UP_REDUCER:
      return {
        ...state,
        loading: false,
        ticket: {},
        ticketImages: [],
        error: '',
        success: false,
      };



    default:
      return state;
  }
}
