import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import BootstrapTable from 'react-bootstrap-table-next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { convertPriceCurrency, sortCaret, headerSortingClasses, NoRecordsFoundMessage, UIDates, remoteGetSelectRow } from '_metronic/_helpers';
import { ProductColumnFormatter } from 'app/modules/documents/document-table/column-formatters';
import * as UIHelpers from '../../documents/_context/DocumentsUIHelpers';
import { useArchiveUIContext } from '../_context/ArchiveUIContext';
import { RemotePagination } from '_metronic/_partials/controls';
import { getConnection } from 'constants/connectionSettings';
import * as columnFormatters from './column-formatters';
import { FormattedMessage, useIntl } from 'react-intl';
import { ArchiveActions } from '../_redux/actions';
import { Icon } from '_metronic/_icons';
import { isArray } from 'lodash';
import clsx from 'clsx';



export function ArchiveTable({ documents, loading, countDocuments, queryParams, setPaginationQuery, setOrderQuery, setSearchQuery }) {

  const dispatch = useDispatch();
  const { formatMessage: intl } = useIntl();

  // UI Context
  const UIContext = useArchiveUIContext();
  const UIProps = useMemo(() => ({
    ids: UIContext.ids,
    setIds: UIContext.setIds,
    requestedArchive: UIContext.requestedArchive,
    openArchiveRelocateDialog: UIContext.openArchiveRelocateDialog,
    openArchivePrintDialog: UIContext.openArchivePrintDialog,
    changeDateRange: UIContext.changeDateRange,
  }), [UIContext]);

  const openArchivePrintDialog = (id) => {
    if (id) {
      const data = [{
        id: [id],
        type: UIProps.requestedArchive.value,
      }];

      dispatch(ArchiveActions.printRequest({ data: data }));
      UIProps.openArchivePrintDialog();
    }
  };

  const COLUMNS = [
    {
      dataField: 'id',
      text: intl({
        id: UIProps.requestedArchive.value === 'orders'
          ? "GENERAL.ID"
          : "DOCUMENT.TABLE.COLUMN.OFFER_ID"
      }),
      // text: intl({ id: 'DOCUMENT.TABLE.COLUMN.DOCUMENT_ID' }, { title: UIProps.requestedArchive.label }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '100px' },
      headerClasses: 'text-nowrap',
      classes: 'text-nowrap',
      formatter: (cell, row) => {
        const orderStatus = UIHelpers.ORDER_STATUS.find(item => item.value === row.order_status)
        return (
          <OverlayTrigger overlay={<Tooltip id="market-tooltip">{UIProps.requestedArchive.value === 'orders' ? orderStatus?.status ?? <FormattedMessage id="GENERAL.OTHER" /> : <FormattedMessage id="SETTINGS.DOCUMENT_REVIEW" />}</Tooltip>}>
            <span>
              {UIProps.requestedArchive.value === 'orders' && <span className={clsx("label label-xl label-dot mr-2", `label-${orderStatus?.className}`)}></span>}
              <span className='font-weight-bold'>{cell}</span>
            </span>
          </OverlayTrigger>
        );
      },
    },
    {
      dataField: UIProps.requestedArchive.value === 'orders' ? 'order_date' : 'created_at',
      // text: intl({ id: 'DOCUMENT.TABLE.COLUMN.DOCUMENT_ID' }, { title: UIProps.requestedArchive.label }),
      text: intl({
        id: UIProps.requestedArchive.value === 'orders'
          ? "DOCUMENT.DETAIL_DIALOG.ORDER.DATE"
          : "DOCUMENT.TABLE.COLUMN.OFFER.DATE"
      }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      classes: 'text-center pr-6',
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '120px' },
      formatter: (cell, row) => (
        <OverlayTrigger overlay={<Tooltip id="market-tooltip">{UIDates.formatDateTime(UIProps.requestedArchive.value === 'orders' ? row.order_date : row.created_at)}</Tooltip>}>
          <span type="button">
            {UIDates.formatDate(UIProps.requestedArchive.value === 'orders' ? row.order_date : row.created_at)}
          </span>
        </OverlayTrigger>
      ),
    },
    {
      dataField: 'position',
      isDummyField: true,
      hidden: !['orders'].includes(UIProps.requestedArchive.value),
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.ARTICLE' }),
      headerStyle: { minWidth: '300px' },
      style: { fontSize: '11px' },
      formatter: (cell, row, rowIndex) => <ProductColumnFormatter cell={cell} row={row} rowIndex={rowIndex} />,
    },
    {
      dataField: 'customer_details',
      text: intl({ id: 'CUSTOMER_TITLE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '180px' },
      formatter: (cell, row) => {
        return (
          <span className="d-flex align-items-center text-nowrap">
            <div className='d-flex flex-column mr-1'>
              <span className='font-weight-bold'>{row.customer_details.name}</span>
              <span className='text-dark-50'>{row.customer_details.email}</span>
            </div>
          </span>
        );
      },
    },
    {
      dataField: 'customer_details.delivery_address.post_code',
      text: intl({ id: 'ADDRESS.DELIVERY' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerStyle: { minWidth: '160px' },
      formatter: (cell, row) => {
        const location = [];
        if (row.customer_details?.delivery_address?.country_code) {
          location.push(row.customer_details?.delivery_address?.country_code);
        }
        if (row.customer_details?.delivery_address?.post_code) {
          location.push(row.customer_details?.delivery_address?.post_code);
        }
        return (
          <div className='d-flex flex-column mr-1'>
            <span className='font-weight-bold text-nowrap'>{row.customer_details.delivery_address.city}</span>
            <span className='text-dark-50'>{location.join('-')}</span>
          </div>
        );
      },
    },
    {
      dataField: 'order_id',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.MARKETPLACE' }),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-nowrap',
      classes: 'text-nowrap',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {

        const orderInfo = <>
          <span className='svg-icon svg-icon-lg mr-2'>
            {getConnection(row.marketplace)?.icon ?? row.marketplace}
          </span>
          <span className='font-weight-bold'>
            {row.order_number ? `#${row.order_number}` : row.order_id ? `#${row.order_id}` : ''}
          </span>
        </>

        return row.marketplace_url !== ''
          ? <OverlayTrigger overlay={<Tooltip id="market-tooltip">{row.marketplace_url}</Tooltip>}>
            <a href={row.marketplace_url} target="_blank" rel="noopener noreferrer" className="text-decoration-none text-muted text-hover-primary">
              {orderInfo}
            </a>
          </OverlayTrigger>
          : <div className="text-muted">
            {orderInfo}
          </div>

      },
    },
    {
      dataField: 'entered_payment',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.PAYMENT_TYPE' }),
      hidden: !['invoices', 'orders', 'waybills'].includes(UIProps.requestedArchive.value),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      classes: 'text-center text-muted font-weight-bold pr-7',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {

        const payments = cell?.map(payment => {
          const method = UIHelpers.paymentMethods.find(method => method.value === payment.payment_method);
          return method ? method.labelId ? intl({ id: method.labelId }) : method.label : payment.payment_method;
        });

        return [...new Set(payments)].join(', ');
      },
    },
    {
      dataField: 'payment_status',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.PAYMENT' }),
      hidden: !['invoices', 'orders', 'waybills'].includes(UIProps.requestedArchive.value),
      sort: true,
      sortCaret,
      onSort: setOrderQuery,
      headerSortingClasses,
      headerClasses: 'text-center text-nowrap',
      headerStyle: { minWidth: '100px' },
      classes: 'text-center',
      formatter: (cell, row) => {
        return (
          <span type='button' className={`label label-inline label-lg font-weight-bold label-${cell === 'paid' ? 'outline-success' : 'danger'}`} onClick={() => UIProps.openEnterPaymentDialog(row.id)} id={`btn_payment_${row.id}`}>
            <FormattedMessage id={cell === 'paid' ? "GENERAL.PAID" : "GENERAL.OPEN"} />
          </span>
        );
      },
    },
    {
      dataField: 'pricing_summary.total',
      text: intl({ id: 'DOCUMENT.TABLE.COLUMN.AMOUNT' }),
      hidden: !['orders'].includes(UIProps.requestedArchive.value),
      isDummyField: true,
      classes: 'font-weight-bold text-right pl-3',
      headerClasses: 'text-right',
      headerStyle: { minWidth: '100px' },
      formatter: (cell, row) => {
        const paymentWarnings = row.payment_warnings?.reduce((acc, curr) => acc + curr.amount, 0) ?? 0;
        const discount = row.pricing_summary?.discount ?? 0;
        const totalPriceDiscount = row.pricing_summary?.total_price_discount ?? 0;
        const totalPayment = +row.pricing_summary.total - (discount * row.pricing_summary?.total / 100) + +row.pricing_summary.shipping_price + paymentWarnings + totalPriceDiscount;
        const currency = row.pricing_summary.currency;
        return (
          <span type="button" onClick={() => UIProps.openEnterPaymentDialog(row.id)}>
            {convertPriceCurrency(totalPayment, currency)}
          </span>
        )
      },
    },
    {
      dataField: 'shipping',
      text: intl({ id: 'GENERAL.LABEL' }),
      headerStyle: { minWidth: '80px' },
      headerClasses: 'text-center',
      classes: 'text-center',
      formatter: (cell, row) => {
        return (
          cell && isArray(cell) && cell.length > 0 ?
            <div className="symbol-group symbol-hover justify-content-center">
              {cell.map((item, index) => {
                const service = UIHelpers.LABEL_OPTIONS.find(service => service.value === item?.service)
                return (
                  <OverlayTrigger key={index} overlay={
                    <Tooltip id="shipping-tooltip">
                      <div className={clsx(item?.error && 'text-danger')}>
                        <div className='font-weight-bolder border-bottom'>
                          {service.label}
                        </div>
                        <div className='font-weight-bold mt-2'>
                          {item?.error ? item?.error : item?.tracking_id}
                        </div>
                      </div>
                    </Tooltip>
                  }>

                    <div className="symbol d-flex bg-white" target="_blank" rel="noopener noreferrer" style={{ fontSize: "2.5rem" }}>
                      {item?.unlabeled ? <Icon.SupplierFIll /> : item?.error ? <Icon.ErrorFill /> : service.icon}
                    </div>

                  </OverlayTrigger>
                )
              })}

            </div>
            : <></>
        )
      }
      // formatter: (cell, row) => {
      //   const shippingIsExist = row.shipping && isArray(row.shipping) && row.shipping.length > 0;
      //   const icon = getConnection(cell)?.icon;
      //   return (
      //     shippingIsExist
      //       ? <OverlayTrigger overlay={<Tooltip id="market-tooltip">{row.shipping[0].service + ": " + row.shipping[0].tracking_id}</Tooltip>}>
      //         <span
      //           target="_blank" rel="noopener noreferrer" className='text-decoration-none text-muted text-hover-primary'>
      //           {icon
      //             ? <span style={{ fontSize: "2.5rem" }}>{icon}</span>
      //             : <label type="button" className="label label-inline label-lg label-outline-primary">
      //               {row.shipping[0].service}
      //             </label>
      //           }
      //         </span>
      //       </OverlayTrigger>
      //       : null
      //   );
      // }
    },
    {
      dataField: 'action',
      text: intl({ id: 'GENERAL.ACTIONS' }),
      classes: 'text-center',
      headerClasses: 'text-center',
      headerStyle: { minWidth: '100px' },
      formatter: columnFormatters.ActionsColumnFormatter,
      formatExtraData: {
        openArchivePrintDialog,
        openArchiveRelocateDialog: UIProps.openArchiveRelocateDialog,
      },
    },
  ];


  return (
    <>
      <BootstrapTable
        keyField="id"
        key={UIProps.ids}
        data={documents || []}
        columns={COLUMNS}
        wrapperClasses="table-responsive"
        classes="table table-head-custom table-vertical-center overflow-hidden"
        striped
        bootstrap4
        bordered={false}
        condensed
        noDataIndication={<NoRecordsFoundMessage loading={loading} onClick={() => { UIProps.changeDateRange(); setSearchQuery('') }} />}
        selectRow={remoteGetSelectRow({
          ids: UIProps.ids,
          setIds: UIProps.setIds,
          entities: documents,
          key: UIProps.requestedArchive.value,
        })}
      />
      <RemotePagination
        queryParams={queryParams.pagination}
        setQueryParams={setPaginationQuery}
        totalCount={countDocuments}
        entitiesCount={documents?.length}
        loading={loading}
      />
    </>
  );
}
