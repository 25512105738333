import ActionTypes from "./actionTypes";



export class ReportActions {

  // Get Order list Data
  static getOrderList = (dateRange) => {
    return {
      type: ActionTypes.GET_ORDER_LIST,
      payload: {
        dateRange
      },
    };
  };

  static getOrderListSuccess = (data) => {
    return {
      type: ActionTypes.GET_ORDER_LIST_SUCCESS,
      payload: {
        data
      },
    };
  };

  static getOrderListFail = () => {
    return {
      type: ActionTypes.GET_ORDER_LIST_FAIL,
    };
  };

}
