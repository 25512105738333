import React, { useEffect, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouteMatch, useHistory, Switch, Redirect, Route } from "react-router-dom";
import { Card, CardBody, CardHeader, CardHeaderToolbar } from "_metronic/_partials/controls";
import { ModuleRoutes, RELATIVE_PATH } from "constants/moduleRoutes";
import { UserGuideHelper } from "app/user-guides/UserGuideHelper";
import { BackButton, SaveButton } from "_metronic/_partials";
import { LoadingDialog } from "_metronic/_partials/loading";
import { importExportActions } from "../_redux/actions";
import ImportProductPreparation from "./import-product/ImportProductPreparation";
import ImportCustomerPreparation from "./import-customer/ImportCustomerPreparation";
import ImportCategoryPreparation from "./import-category/ImportCategoryPreparation";
import { FormattedMessage } from "react-intl";
import ImportSelect from "./ImportSelect";
import ImportTable from "./ImportTable";
import ImportUpload from "./ImportUpload";
import InfoAccordion from "./InfoAccordion";
import { Col, Row } from "react-bootstrap";
import { Icon } from "_metronic/_icons";



export default function ImportPage() {

  const dispatch = useDispatch();
  const history = useHistory();
  const match = useRouteMatch("/import/:param");

  const [importType, setImportType] = useState(null);

  const getImportType = (type) => setImportType(type);

  const { loading, transportData, importPreparation } = useSelector(state => ({
    loading: state.importExport.loading,
    transportData: state.importExport.transportData,
    importPreparation: state.importExport.importPreparation
  }), shallowEqual);

  useEffect(() => {
    dispatch(importExportActions.getImportExports("import"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);


  useEffect(() => {
    if (importType) {
      dispatch(importExportActions.checkData(importType))
    };

    return () => {
      dispatch(importExportActions.resetFormHandler());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, importType]);

  const submitBtnRef = useRef();
  const submitBtnRefClick = () => { submitBtnRef.current && submitBtnRef.current.click() };


  return (
    <Card className="card-box">

      <CardHeader
        icon={!(importType || match) && <Icon.CloudUpload />}
        back={(importType || match) && <BackButton onClick={() => { setImportType(null); history.push(ModuleRoutes.IMPORT) }} />}
        title={<FormattedMessage id="IMPORT_EXPORT.IMPORT" />}
        info={<UserGuideHelper />}
        className="display-5"
        id='title_accounting_import'
        sticky
      >
        <CardHeaderToolbar>
          {importPreparation.length > 0 && match && <SaveButton disabled={loading} onClick={submitBtnRefClick} />}
        </CardHeaderToolbar>

      </CardHeader>

      <CardBody className="d-flex flex-column">
        <Switch>
          <Redirect exact={true} from={RELATIVE_PATH} to={ModuleRoutes.IMPORT} />

          <Route path={ModuleRoutes.IMPORT_PRODUCT}>
            <ImportProductPreparation submitBtnRef={submitBtnRef} />
          </Route>

          <Route path={ModuleRoutes.IMPORT_CUSTOMER}>
            <ImportCustomerPreparation submitBtnRef={submitBtnRef} />
          </Route>

          <Route path={ModuleRoutes.IMPORT_CATEGORY}>
            <ImportCategoryPreparation submitBtnRef={submitBtnRef} />
          </Route>

          <Route path={ModuleRoutes.IMPORT}>
            {!importType
              ? <Row>
                <Col md="9">
                  <ImportTable importData={transportData} loading={loading} />
                </Col>
                <Col md="3">
                  <ImportSelect importType={importType} getImportType={getImportType} />
                </Col>
              </Row>
              : <>
                <ImportUpload importType={importType} />
                <InfoAccordion importType={importType} />
              </>
            }
          </Route>

        </Switch>
      </CardBody>

      <LoadingDialog isLoading={loading} />

    </Card>
  )
}
