import { useState, useEffect } from "react";
import { isEmpty, isString } from "lodash";



export function usePdfMaker(pdf) {

  const [status, setStatus] = useState("LOADING");
  const [result, setResult] = useState();

  useEffect(() => {
    try {
      if (!isEmpty(pdf) && pdf.Status === "OK" && isString(pdf.PDF)) {

        const bytes = window.atob(pdf.PDF);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
          out[length] = bytes.charCodeAt(length);
        }

        const blob = new Blob([out], { type: "application/pdf" });
        const urls = blob && URL.createObjectURL(blob);
        setStatus("OK");
        setResult(urls);
      }
      if (pdf.Status === "error") {
        setResult(pdf.PDF);
      }
    } catch (error) {
      setStatus("ERROR");
      setResult(JSON.stringify(error));
    }

  }, [pdf]);

  return [status, result];
}