import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';
import { Card, CardBody, CardSubHeader, SVReactSelect } from '_metronic/_partials/controls';
import { paymentMethods } from '../../documents/_context/DocumentsUIHelpers';
import { useIntl } from 'react-intl';
import { Icon } from '_metronic/_icons';
import { UserInfoDropdown } from '_metronic/_partials';



export function PaymentsMethod({ loading }) {
  const { formatMessage: intl } = useIntl();
  return (
    <Card className='card-stretch gutter-b'>

      <CardSubHeader
        icon={<Icon.Cash />}
        title={<FormattedMessage id="SETTINGS.DOCUMENT.PAYMENT_TITLE" />}
        info={<UserInfoDropdown
          title={<FormattedMessage id='SETTINGS.DOCUMENT.PAYMENT_TITLE' />}
          description={<FormattedMessage id='USER_GUIDE.SETTINGS.DOCUMENT.PAYMENT' />} />}
      />

      <CardBody>
        <Field name="default_payment.method" component={SVReactSelect}
          {...{
            options: paymentMethods,
            label: intl({ id: "SETTINGS.DOCUMENT.PAYMENT_LABEL" }),
            withFeedbackLabel: true,
            feedbackLabel: true,
            disabled: loading,
            //placeholder: intl({ id: 'SETTINGS.DOCUMENT.PAYMENT_PLACEHOLDER' }),
          }}
        />
      </CardBody>
    </Card>
  );
}