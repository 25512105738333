import { COUNTRY_DE } from "_metronic/_helpers";
import ActionTypes from "./actionTypes";

const INITIAL_STATE = {
  loading: false,
  isLoaded: false,
  isImport: false,
  error: "",
  checkData: [],
  transportData: [],
  importPreparation: []
};



export function importExportReducer(state = INITIAL_STATE, { type, payload }) {
  switch (type) {


    case ActionTypes.ACCOUNTING_EXPORT_CLEAR:
      return {
        ...state,
        loading: false,
        isLoaded: false,
      };


    // Import Example
    case ActionTypes.IMPORT_EXAMPLE:
      return {
        ...state,
        loading: true,
        isLoaded: false,
      };

    case ActionTypes.IMPORT_EXAMPLE_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoaded: true,
      };

    case ActionTypes.IMPORT_EXAMPLE_FAIL:
      return {
        ...state,
        loading: false,
        isLoaded: false,
        error: 'IMPORT_EXPORT.EXAMPLE_UPLOAD_ERROR',
      };


    // Export get
    case ActionTypes.IMPORT_EXPORTS_GET:
      return {
        ...state,
        loading: true,
        isLoaded: false,
      };

    case ActionTypes.IMPORT_EXPORTS_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoaded: true,
        transportData: payload.data,
      };

    case ActionTypes.IMPORT_EXPORTS_GET_FAIL:
      return {
        ...state,
        loading: false,
        isLoaded: false,
        error: 'IMPORT_EXPORT.EXAMPLE_UPLOAD_ERROR',
      };


    // Export delete
    case ActionTypes.IMPORT_EXPORT_DELETE:
      return {
        ...state,
        loading: true,
        isLoaded: false,
      };

    case ActionTypes.IMPORT_EXPORT_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoaded: true,
        transportData: [...state.transportData.filter(item => item.id !== payload.id)]
      };

    case ActionTypes.IMPORT_EXPORT_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        isLoaded: false,
        error: 'IMPORT_EXPORT.EXAMPLE_UPLOAD_ERROR',
      };



    // Export download
    case ActionTypes.IMPORT_EXPORT_DOWNLOAD:
      return {
        ...state,
        loading: true,
        isLoaded: false,
      };

    case ActionTypes.IMPORT_EXPORT_DOWNLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoaded: true,
      };

    case ActionTypes.IMPORT_EXPORT_DOWNLOAD_FAIL:
      return {
        ...state,
        loading: false,
        isLoaded: false,
        error: 'IMPORT_EXPORT.EXAMPLE_UPLOAD_ERROR',
      };


    // Export get
    case ActionTypes.EXPORT_GET:
      return {
        ...state,
        loading: true,
        isLoaded: false,
      };

    case ActionTypes.EXPORT_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoaded: true,
      };

    case ActionTypes.EXPORT_GET_FAIL:
      return {
        ...state,
        loading: false,
        isLoaded: false,
        error: payload?.err?.message,
      };


    // Import Add
    case ActionTypes.IMPORT_PREPARATION:
      let importPreparation = payload.data

      if (payload.type === "customers") {
        const updateCustomer = (item) => {
          const checkCountryCode = (code) => COUNTRY_DE.some(country => country.value === code?.toUpperCase()) ? code?.toUpperCase() : "";
          const {  country_code_1, country_code_2 } = item;
          return {
            ...item,
            country_code_1: checkCountryCode(country_code_1),
            country_code_2: checkCountryCode(country_code_2)
          }
        };

        importPreparation = importPreparation.map(updateCustomer);
      }

      return {
        ...state,
        loading: false,
        isLoaded: true,
        importPreparation,
      };

    // Import Update
    case ActionTypes.IMPORT_PREPARATION_UPDATE:
      const updated = payload.data;
      const updatedImportData = state.importPreparation.map(row =>
        row.id === updated.id ? updated : row
      );
      return {
        ...state,
        loading: false,
        isLoaded: true,
        importPreparation: updatedImportData,
      };

    // Import Delete
    case ActionTypes.IMPORT_PREPARATION_DELETE:
      return {
        ...state,
        loading: false,
        isLoaded: true,
        importPreparation: [...state.importPreparation.filter(item => item.id !== payload.id)],
      };




    // Import Create
    case ActionTypes.IMPORT_PRODUCT:
      return {
        ...state,
        loading: true,
        isLoaded: false,
        isImport: false,
      };

    case ActionTypes.IMPORT_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoaded: true,
        isImport: true,
      };

    case ActionTypes.IMPORT_PRODUCT_FAIL:
      return {
        ...state,
        loading: false,
        isLoaded: false,
        isImport: false,
        error: payload?.err?.message,
      };



    // Import Create
    case ActionTypes.IMPORT_CUSTOMER:
      return {
        ...state,
        loading: true,
        isLoaded: false,
        isImport: false,
      };

    case ActionTypes.IMPORT_CUSTOMER_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoaded: true,
        isImport: true,
      };

    case ActionTypes.IMPORT_CUSTOMER_FAIL:
      return {
        ...state,
        loading: false,
        isLoaded: false,
        isImport: false,
        error: payload?.err?.message,
      };


    // Import Category Create
    case ActionTypes.IMPORT_CATEGORY:
      return {
        ...state,
        loading: true,
        isLoaded: false,
        isImport: false,
      };

    case ActionTypes.IMPORT_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoaded: true,
        isImport: true,
      };

    case ActionTypes.IMPORT_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        isLoaded: false,
        isImport: false,
        error: payload?.err?.message,
      };


    // Check Data
    case ActionTypes.CHECK_DATA:
      return {
        ...state,
        loading: true,
        isLoaded: false,
      };

    case ActionTypes.CHECK_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        isLoaded: true,
        checkData: payload.data
      };

    case ActionTypes.CHECK_DATA_FAIL:
      return {
        ...state,
        loading: false,
        isLoaded: false,
        error: payload?.err?.message,
      };


    // Clean State
    case ActionTypes.CLEAN_UP_REDUX_STATE: {
      return {
        ...state,
        loading: false,
        isLoaded: false,
        error: "",
      };
    }


    // Clean State
    case ActionTypes.RESET_FORM_HANDLER: {
      return {
        ...state,
        loading: false,
        isLoaded: false,
        isImport: false,
        error: "",
        importPreparation: [],
        checkData: []
      };
    }


    default:
      return state;
  }
}