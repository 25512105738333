

export const searchProperties = [
  'name',
  'sku',
  'variants.sku',
  'variants.ean',
  'variants.asin',
  'identities.ean',
  'identities.asin',
  'pricing.purchase_price',
  'identities.barcode',
  'identities.gtin',
  'identities.isbn',
];



export const initialQueryParams = { page: 1, limit: 10 };
export const initialOrderParams = 'id DESC';

export const initialSearchParams = {
  searchText: '',
  searchKeys: searchProperties,
};

export const initialProductQueryParams = {
  pagination: initialQueryParams,
  search: initialSearchParams,
  filter: null,
  order: initialOrderParams,
};

export const initShippingValue = {
  method: '',
  shippingPrice: 0,
  shippingAdditionalPrice: 0,
  interShippingCheckbox: [],
  processingTime: '',
  type: '',
};

export const initialProductValues = {
  id: '',
  name: '',
  sku: '',
  condition: 'NEW',
  brand: '',
  quantity: 0,
  unit: '',
  supplier_id: '',
  pricing: {
    purchase_price: 0,
    /* net_price: 0, */
    gross_price: 0,
    tax: 19,
    currency: '',
  },
  category: {
    id: '',
    name: '',
    path: '',
    key: ""
  },
  images: [],
  description: '',
  //short_description: '',
  identities: {
    asin: '',
    gtin: '',
    isbn: '',
    ean: '',
    upc: '',
    barcode: '',
    mpn: '',
  },
  dimensions: {
    length: 0,
    width: 0,
    height: 0,
    weight: 0,
  },
  features: [],
  variants: [],
  inventories: [],
  digital_product: {},
};

export const initialRequestValues = {
  loading: false,
  error: '',
  status: '', // success || error || pending
  id: null, // request id
};

export const initialProductKey = {
  key: '',
  order_id: '',
  order_date: null,
  email_sended_date: null,
  confirmation_date: null,
  created_at: null,
  updated_at: new Date(),
};


export const TAX_OPTIONS = [
  {
    value: 0,
    // label: <FormattedMessage id="PRODUCT.GENERAL.TAX_SELECT" />,
    label: 'MwSt. Frei',
  },
  {
    value: 7,
    label: '7%  - DE',
  },
  {
    value: 17,
    label: '17% - LU',
  },
  {
    value: 18,
    label: '18% - MT',
  },
  {
    value: 19,
    label: '19% - DE/RO/CY',
  },
  {
    value: 20,
    label: '20% - BG/FR/AT/SK',
  },
  {
    value: 21,
    label: '21% - BE/LV/LT/NL/ES/CZ',
  },
  {
    value: 22,
    label: '22% - IT/SI',
  },
  {
    value: 23,
    label: '23% - IE/PL/PT',
  },
  {
    value: 24,
    label: '24% - FI/GR',
  },
  {
    value: 25,
    label: '25% - DK/HR/SE',
  },
  {
    value: 27,
    label: '27% - HU',
  },
];


export const UNIT_OPTIONS = [
  {
    value: 'piece',
    label: 'PRODUCT.GENERAL.CONDITIONS.PIECE',
  },
  {
    value: 'weight',
    label: 'PRODUCT.GENERAL.CONDITIONS.WEIGHT',
  },
  {
    value: 'hour',
    label: 'PRODUCT.GENERAL.CONDITIONS.HOUR',
  },
  {
    value: 'length',
    label: 'PRODUCT.GENERAL.CONDITIONS.LENGTH',
  },
  {
    value: 'area',
    label: 'PRODUCT.GENERAL.CONDITIONS.AREA',
  },
  {
    value: 'volume',
    label: 'PRODUCT.GENERAL.CONDITIONS.VOLUME',
  },
  {
    value: 'parcel',
    label: 'PRODUCT.GENERAL.CONDITIONS.PARCEL',
  }
];

export const pictureSpecs = {
  width: 1200,
  height: 1200,
  size: 3145728, // 3MB
  format: ['jpeg', 'jpg', 'gif', 'png', 'webp'],
  maxNumber: 10,
};

export const IS_VARIANT_OPTIONS = [
  {
    value: 0,
    label: "0",
  },
  {
    value: 1,
    label: "1",
  },
];



// const productConditions = [
//   {
//     value: 'NEW',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.NEW' }),
//   },
//   {
//     value: 'USED',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.USED' }),
//   },
//   {
//     value: 'REFURBISHED',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.REFURBISHED' }),
//   },
//   {
//     value: 'LIKE_NEW',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.LIKE_NEW' }),
//   },
//   {
//     value: 'VERY_GOOD',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.VERY_GOOD' }),
//   },
//   {
//     value: 'GOOD',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.GOOD' }),
//   },
//   {
//     value: 'ACCEPTABLE',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.ACCEPTABLE' }),
//   },
//   {
//     value: 'FOR_PARTS_OR_NOT_WORKING',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.FOR_PARTS_OR_NOT_WORKING' }),
//   },
//   {
//     value: 'REPAIR_OR_EXCHANGE',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.REPAIR_OR_EXCHANGE' }),
//   },
//   {
//     value: 'UNSPECIFIED',
//     label: intl({ id: 'PRODUCT.GENERAL.CONDITIONS.UNSPECIFIED' }),
//   },
// ];
