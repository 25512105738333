import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AuthActions } from '../auth/_redux/actions';
import DOMPurify from 'dompurify';



export default function TermsConditions() {
  
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AuthActions.contract("conditions"));
  }, [dispatch]);


  const { contract } = useSelector(state => ({
    contract: state.auth.contract,
  }));


  return (
    <>
      {contract && <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(contract) }} />}
    </>
  );
}
