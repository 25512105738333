import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Card, CardBody, CardSubHeader, SVFormControl, SVReactSelect } from "_metronic/_partials/controls";
import { getCountryList } from '_metronic/_helpers';
import { useLang } from "_metronic/i18n/Metronici18n";
import { Col, Row } from "react-bootstrap";
import { Icon } from '_metronic/_icons';
import { Field } from "formik";



export function Address({loading}) {

  const locale = useLang();

  const { formatMessage: intl } = useIntl();

  const INPUTS = [
    {
      name: "company_name",
      label: "GENERAL.COMPANY_NAME",
      required: true
    },
    {
      name: "owner",
      label: "GENERAL.OWNER",
      required: true
    },
    {
      name: "email",
      label: "GENERAL.EMAIL",
      required: true
    },
    {
      name: "phone",
      label: "GENERAL.PHONE",
    },
    {
      name: "mobile",
      label: "GENERAL.MOBILE",
    },
    {
      name: "web",
      label: "GENERAL.WEB",
    },
    {
      name: "fax",
      label: "GENERAL.FAX",
    },
    {
      name: "address.address_line1",
      label: "ADDRESS.STREET",
      required: true
    },
    {
      name: "address.post_code",
      label: "ADDRESS.PLZ",
      required: true
    },
    {
      name: "address.city",
      label: "ADDRESS.CITY",
      required: true
    },
    {
      name: "address.country_code",
      label: "ADDRESS.COUNTRY",
      options: getCountryList(locale) ,
      component: SVReactSelect,
      required: true
    }

  ];


  return (
    <Card className="gutter-b">

      <CardSubHeader
        icon={<Icon.GeoAlt />}
        title={<FormattedMessage id="COMPANY.ADDRESS_TITLE" />}
      />

      <CardBody>

        <Row>
          {INPUTS.map((item, i) => (
            <Col lg="6" className="col-xl-4" key={i}>
              <Field component={item.component || SVFormControl}
                {...{
                  name: item.name,
                  label: intl({ id: item.label }),
                  options: item.options,
                  withFeedbackLabel: false,
                  feedbackLabel: true,
                  required: item.required,
                  disabled: loading
                }}
              />
            </Col>
          ))}
        </Row>

      </CardBody>
    </Card>
  );
};